<template>
  <!-- Header -->
  <div class="component-header-wrapper" :style="{height: isMobile ? '0' : '60px'}">

    <!-- Left -->
    <div class="back-btn" :class="[isMobile? 'hide': '']" @click="goback()">
      <span class="iconfont icon-fanhui" />
      <div class="back-title">ORDZ GAMES</div>
    </div>

    <!-- Center -->
    <div class="link-container">
    </div>

    <!-- Right -->
    <div class="link-box">

      <template>
        <!-- Connect Wallet -->
        <ComponentConnectWallet ref="ComponentConnectWallet" :isMobile="isMobile" @updateGlobalMessage="updateGlobalMessage" @updateConnectWalletInfo="updateConnectWalletInfo" />
      </template>

    </div>

  </div>
</template>

<script>
// Components
import ComponentConnectWallet from '@/components/connect-wallet'

export default {
  components: {
    ComponentConnectWallet // component connect wallet
  },
  props: ['isMobile'],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    goback() {
      if (process.env.VUE_APP_EVN === 'production') {
        location.href = 'https://www.ordz.games/?page=home'
      } else {
        location.href = 'https://staging.ordz.games?page=home'
      }
    },
    updateGlobalMessage(data){
      this.$emit('updateGlobalMessage', data)
    },
    updateConnectWalletInfo(data){
      this.$emit('updateConnectWalletInfo', data)
    },
    openConnectWallet(){
      this.$refs.ComponentConnectWallet.openConnectWallet()
    },
  },
}
</script>

<style lang="scss" scoped>
  .component-header-wrapper {
    // background: #000;
    position: fixed;
    left: 0;
    top: 20px;
    z-index: 9;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;

    .back-btn {
      cursor: pointer;
      color: #9aa3ac;
      //padding: 5px 15px;
      font-size: 20px;
      font-weight: 600;
      //border: 2px solid #9aa3ac;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Silkscreen;
      &.hide {
        display: none;
      }
      .iconfont {
        font-size: 20px;
      }
      .back-title {
        margin-top: -3px;
      }
      &:hover {
        //border-color: var(--theme-color);
        color: var(--theme-color);
        //background: rgba(255, 146, 14, .06);
      }
    }


    .link-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      > a {
        text-align: center;
        min-width: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 8px 16px;
        margin: 0 8px;
        border-bottom: 2px solid transparent;
        font-size: 1.2857rem;
        white-space: nowrap;
        position: relative;
        transition: all .2s ease-in-out;

        &::before,
        &::after {
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          bottom: 0;
          background-color: #8a8a8a;
          transition: all .2s ease-in-out;
        }

        &:hover::before {
          width: 100%;
        }

        &.active::after {
          width: 100%;
          background: var(--theme-color);
        }

      }

    }

    .link-box {
      display: flex;
      align-items: center;
      gap: 16px;

      .link-item {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        &:hover {
          opacity: 0.8;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

      }

    }

    a {
      text-decoration: none;
    }

  }
</style>
<style lang="scss">
  ._mobile{
    .component-header-wrapper{
      z-index: 101;
      height: 0;
    }
  }
</style>
