import QRCode from 'qrcode'
import Cookies from 'js-cookie'

const cookies = Cookies.withAttributes({ path: '/', domain: '.ordz.games' })

/**
 * 输入字符串，输出二维码
 *
 * @param {string} address 需要转换成二维码的字符串
 * @param {function} cb 成功的回调函数
 * @return {undefined} undefined
 */
const utilCreateQRCode = (address, cb, margin = 4) => {
  try {
    if (!address) return
    QRCode.toDataURL(address, {
      margin,
      errorCorrectionLevel: 'H',
      scale: 10
    }, (err, url) => {
      cb && cb(url)
    })
  } catch (error) {
    console.error('utilCreateQRCode error: ', error)
  }
}

// get uid
const createUuid = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 阻止页面滚动
 *
 * @param {boolean} flag ture-阻止 body 滚动  false-恢复默认滚动
 * @return {undefined} undefined
 */
const utilStopBodyScroll = (flag) => {
  try {
    if (flag) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100vh'
    } else {
      document.body.style.overflow = 'unset'
      const windowHeight = window.innerHeight;
      document.body.style.height = window.isMobile ? `${windowHeight}px` : 'auto'
    }
  } catch (error) {
    console.error('utilStopBodyScroll error: ', error)
  }
}

// 拆分一个大数组
function sliceArray (arr, size) {
  var arr2 = [];
  for(var i=0; i<arr.length; i=i+size){
    arr2.push(arr.slice(i,i+size));
  }
  return arr2;
}

export {
  createUuid,
  utilStopBodyScroll,
  sliceArray,
  utilCreateQRCode,
  cookies
}
