<template>
  <div class="inscription-content" :class="[isMobile ? `_mobile ${onlayPc ? 'onlay-pc' : 'm'} ${scaleGame ? 'scale-game' : ''} ${showFooter ? 'show-footer' : ''}` : '', gameName]">
    <!-- header -->
    <Header ref="ComponentHeader" :isMobile="isMobile" @updateGlobalMessage="updateGlobalMessage" @updateConnectWalletInfo="updateConnectWalletInfo" />

    <!-- 如果是pc端就展示下面 -->
    <div class="inscription-top" v-if="!isMobile || !hideInscriptionType[gameName]">
      <div class="inscription-title">Inscription <p class="inscription-number" style="margin-left: 8px;">{{ inscriptionNumber }}</p></div>
    </div>
    <!-- 如果是移动端就展示下面 -->
    <div class="inscription-info"  :class="[isMobile ? `${onlayPc ? '' : 'mobile'}` : '']">
      <div class="game-boy displayButton"><img :src="gameboyUrl"/></div>
      <iframe :src="gameFrameUrl" frameborder="0" id="gameFrame" class="area" @load="iframeLoad()" :style="{...(gameFrameScale||{})}"></iframe>
      <!-- <object :data="gameFrameUrl" frameborder="0" id="gameFrame" class="area" @load="iframeLoad()" :style="{...(gameFrameScale||{})}"
      type="text/html"> </object> -->

      <!-- <iframe src="http://192.168.50.249:62285/网站-index-change.html" frameborder="0" id="gameFrame" class="area" onload="sendGameMessage(info='')"></iframe> -->
      <div class="game-button displayButton up-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'up-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'up-button'})">
        <img :src="upUrl"/>
      </div>
      <div class="game-button displayButton left-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'left-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'left-button'})">
        <img :src="leftUrl"/>
      </div>
      <div class="game-button displayButton right-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'right-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'right-button'})">
        <img :src="rightUrl"/>
      </div>
      <div class="game-button displayButton down-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'down-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'down-button'})">
        <img :src="downUrl"/>
      </div>
      <div class="game-button displayButton left-red-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'left-red-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'left-red-button'})">
        <img :src="redBUrl"/>
      </div>
      <div class="game-button displayButton right-red-button" @touchend="touchAction({keyType: 'keyup', classNanme: 'right-red-button'})" @touchstart="touchAction({keyType: 'keydown', classNanme: 'right-red-button'})">
        <img :src="redAUrl"/>
      </div>

      <!-- 摇杆按钮 -->
      <div class="game-joystick-content">
        <div class="game-joystick-button"></div>
      </div>

    </div>

    <!-- 全局提示 -->
    <div class="global-message" :class="[!!globalMessage.message ? 'active' : '', globalMessage.type]">{{ globalMessage.message }}</div>

    <!-- footer -->
    <div class="inscription-footer" v-if="showFooter">
      <div class="action-buttons">
        <!-- <button class="common-default-btn common-hover-status-bg" @click="toAirdrop" @touchstart="toAirdrop">AIRDROP#1</button> -->
<!--        <button class="common-default-btn common-hover-status-bg" @click="toPlay" @touchstart="toPlay">HEROES</button>-->
<!--        heroes claim-->
        <button class="common-default-btn common-hover-status-bg" @click="handleClaim()" @touchstart="handleClaim" v-if="gameName !== 'ape-rush'">CLAIM</button>
<!--        ape rush claim-->
        <template v-else>
          <button class="common-default-btn common-hover-status-bg" @click="apeRushHandleClaim()" @touchstart="apeRushHandleClaim">CLAIM</button>
          <!-- <button class="common-default-btn common-hover-status-bg" @click="toApeRushLeaderboard()" @touchstart="toApeRushLeaderboard()">LEADERBOARD</button> -->
        </template>
        <!-- <div class="button" @click="toAirdrop">AIRDROP #1</div> -->
        <!-- <div class="button" @click="handleClaim()">CLAIM</div> -->
        <!-- <div class="button" @click="sharePageUrl">SHARE</div> -->
      </div>
      <div class="introduce">
        PLAY, Share, Hold and GET READY TO EARN $ORDG$
      </div>
    </div>

    <!-- Modal - claim points -->
<!--    <ComponentModalClaimPoints ref="ComponentModalClaimPoints"-->
<!--      :isMobile="isMobile"-->
<!--      :heroeList="heroeList"-->
<!--      @sharePageUrl="sharePageUrl"-->
<!--    />-->

    <ComponentModalClaimPoints ref="ComponentModalClaimPoints"
       :isMobile="isMobile"
       :heroeList="heroeList"
       @sharePageUrl="sharePageUrl"
    />

    <ComponentModalApeClaimPoints ref="ComponentModalApeClaimPoints" :isMobile="isMobile" />

    <ComponentModalClaimPointsMultiple ref="ComponentModalClaimPointsMultiple"
      :isMobile="isMobile"
      :heroeList="heroeList"
      @sharePageUrl="sharePageUrl"
    />

  </div>
</template>

<script>
import axios from "axios";

// utils
import * as utils from "@/utils";
import * as constans from "@/config/constans";
import wallet from '@/utils/wallet'
// components
import Header from "@/components/header";
import ComponentModalClaimPoints from '@/components/modal/claim-points'
import ComponentModalApeClaimPoints from '@/components/modal/claim-points-ape'
import ComponentModalClaimPointsMultiple from '@/components/modal/claim-points-multiple'

// 图片
import gameboyUrl from "@/assets/img/gameboy.png";
import redUrl from "@/assets/img/red.png";
import upUrl from "@/assets/img/up.png";
import leftUrl from "@/assets/img/left.png";
import rightUrl from "@/assets/img/right.png";
import downUrl from "@/assets/img/down.png";
import redActiveUrl from "@/assets/img/red-active.png";
import upActiveUrl from "@/assets/img/up-active.png";
import leftActiveUrl from "@/assets/img/left-active.png";
import rightActiveUrl from "@/assets/img/right-active.png";
import downActiveUrl from "@/assets/img/down-active.png";

export default {
  data() {
    return {
      /**
       * claim 相关
       */
      cliamGameName: '', // 游戏名
      cliamEmail: '', // 邮箱
      claimToken: '', // token
      claimAddress: '', // 地址
      currentRoleInfo: {}, // 养成游戏中当前角色

      onlayPc: false,
      isMobile: false,
      gameName: '',
      gameFrameUrl: '',
      inscriptionNumber: '',

      linkShareCode: '',
      myShareCode: '',
      myAccountEmail: '',
      iframeLoadEd: false,

      //移动端素材
      gameboyUrl,
      redAUrl: redUrl,
      redBUrl: redUrl,
      upUrl,
      leftUrl,
      rightUrl,
      downUrl,
      buttonUrl: {
        redUrl,
        upUrl,
        leftUrl,
        rightUrl,
        downUrl,
        redActiveUrl,
        upActiveUrl,
        leftActiveUrl,
        rightActiveUrl,
        downActiveUrl,
      },

      // 移动端游戏
      ATouchDown: false,
      BTouchDown: false,

      // 全局提示
      globalMessage: {
        message: '',
        type: '',
      },

      connectWalletInfo: {}, // 钱包信息

      gameNameMap: {
        rocket: 'ordz-starship',
        ordzRush: 'ordz-rush',
        ordzCity: 'ordz-city',
        'ordz-merge': 'ordz-pfp-2048',
        'ordz-snake': 'ordz-pfp-snake',
        'ordz-heroes': 'ordz-heroes',
        'ordz-match': 'ordz-match',
        'ordz-pk': 'ordz-pk',
        'banana': 'banana',
        'gameboy': 'gameboy'
      },
      gameFrameScale: null, //游戏放大
      customGameInscription: { //自定义游戏类型
        'a8c0babda3b32d252bf4f493999d2e7c5218aa8f9ca64b899186d32c719345cbi0': {
          gameName: 'rocket',
          inscriptionNumber: '1192300',
        },
        '030290b1cc28b8f91c27df3be8b13a001ff2e3b8bff8022e0e27fa656ea55410i0': {
          gameName: 'ordz-merge',
          inscriptionNumber: 'Ordz Merge',
        },
        '2e988961208c9d93d9eed5dbc1c51476533ca97c355af45d290c33a8f1fc3c10i0': {
          gameName: 'ordz-snake',
          inscriptionNumber: 'Ordz Snake',
        },
        'ordz-heroes': {
          gameName: 'ordz-heroes',
          inscriptionNumber: 'Ordz Heroes',
        },
        'ordz-pk': {
          gameName: 'ordz-pk',
          inscriptionNumber: 'Ordz PK',
        },

        '66edac2d9864a3cd323c60d7c7284b49048285cc4700a03f096bf1d821c67156i0': {
          gameName: 'ordz-match',
          inscriptionNumber: '11749909',
        },
        '478153addc6b0d79c1c10c2dcc8c93255cfa8c2e1a3dc3c84f07f0a61cd2648di0': {
          gameName: 'ordz-match',
          inscriptionNumber: '11976968',
        },
        // 暂时没刻
        'banana': {
          gameName: 'ape-rush',
          inscriptionNumber: 'banana',
        },
        'gameboy': {
          gameName: 'ape-rush',
          inscriptionNumber: 'gameboy',
        },
        // 'ordz-match': {
        //   gameName: 'ordz-match',
        //   inscriptionNumber: 'Ordz Match',
        // },
        // 'ordz-merge': {
        //   gameName: 'ordz-merge',
        //   inscriptionNumber: 'Ordz Merge',
        // },
        // 'ordz-snake': {
        //   gameName: 'ordz-snake',
        //   inscriptionNumber: 'Ordz Snake',
        // },
      },
      customGameRouter: { //自定义路由类型
        '3e5162e06900a48e247f09c8624719ef8d283f7c9d2b2b13c51349ca31769e46i0': { //之前用的，之后要用17481979
          gameName: 'ordz-block-snake',
          inscriptionNumber: '14124101',
          url: 'https://ordz-snake-builder.ordz.games',
        },
        '315198afb24aed9bc0b68f222eb6f2e976b7ab62bac456b30f26cbb8b893ae55i0': {
          gameName: 'ordz-block-snake',
          inscriptionNumber: '17481979',
          url: 'https://ordz-snake-builder.ordz.games',
        },
      },
      hideInscriptionType: { //隐藏inscription信息的类型
        'ordz-heroes': true,
        'ordz-pk': true,
      },
      // heroes
      collectionName: 'ordz-pfp', //项目名
      heroeList: [], //可选角色
      followTarget: null // 白板用户跟随的目标人的信息
    };
  },
  components: {
    Header,
    ComponentModalClaimPoints,
    ComponentModalClaimPointsMultiple,
    ComponentModalApeClaimPoints
  },

  created(){
    let { query, params, name } = this.$route
    let { hash } = params
    let { og } = query
    this.linkShareCode = og

    if(name === 'OrdzHeroes'){
      // 检查所属游戏
      this.checkGame('ordz-heroes')
    }else if(name === 'OrdzPk'){
      // 检查所属游戏
      this.checkGame('ordz-pk')
    }else{
      // 检查所属游戏
      this.checkGame(hash)
    }

    // 去除手指放大
    this.removeTouch()

  },
  watch: {
    connectWalletInfo: {
      deep: true,
      handler(newVal, oldVal){
        if(newVal.address){
          this.accountLogin(newVal.address)
        }
      }
    },
    // 更新邮箱
    updateEmailState(newVal){
      if(!!newVal){
        this.updateGameEmail()
      }
    },
  },
  computed:{
    // 计算满足更新邮箱状态
    updateEmailState: function(){
      return this.iframeLoadEd && !!this.myAccountEmail
    },
    // 是否显示底部
    showFooter: function(){
      let showType = ['ordz-merge', 'ordz-snake', 'ordz-heroes', 'ordz-match', 'ordz-pk', 'ape-rush']
      return showType.includes(this.gameName)
    },
    // 是否强制缩放游戏
    scaleGame: function(){
      let showType = ['ordz-merge', 'ordz-match']
      return showType.includes(this.gameName)
    },

  },
  mounted(){
    //监听子级传过来的message事件
    window.addEventListener("message", this.receiveSonMessage, false); // 获取子页面传递过来的数据

    let joystickButton = document.querySelector('.game-joystick-content')
    joystickButton.addEventListener("touchstart", this.joystickTouchStart, false);
    joystickButton.addEventListener("touchend", this.joystickTouchEnd, false);
    joystickButton.addEventListener("touchmove", this.joystickTouchMove, false);
  },
  beforeDestroy(){
    window.removeEventListener("message", this.receiveSonMessage, false); // 获取子页面传递过来的数据

    let joystickButton = document.querySelector('.game-joystick-content')
    joystickButton.removeEventListener("touchstart", this.joystickTouchStart, false);
    joystickButton.removeEventListener("touchend", this.joystickTouchEnd, false);
    joystickButton.removeEventListener("touchmove", this.joystickTouchMove, false);
  },
  methods: {
    // cliamGameName: '', // 游戏名
    // cliamEmail: '', // 邮箱
    // claimToken: '', // token
    // claimAddress: '', // 地址

    // claim ape rush
    async apeRushHandleClaim () {
      if (!this.gameName) return

      // 没开始玩或者，正在玩 （显示第一步，有输入的邮箱就赋值，到第二步时，登录了地址就赋值）
      // 完成了游戏 （没有登录 第二步，登录了 第三步）

      // 游戏名
      this.cliamGameName = this.gameNameMap[this.gameName]
      this.claimAddress = this.connectWalletInfo.address

      // 没有登录，打开钱包
      if(!this.claimAddress){
        this.$refs.ComponentHeader.openConnectWallet()
        return
      }

      let data = {
        gameName: this.cliamGameName,
        email: this.cliamEmail || this.myAccountEmail,
        token: this.claimToken,
        address: this.claimAddress,
        currentRole: this.currentRoleInfo,
        followTarget: this.followTarget
      }

      // let res = await axios.post(`${constans.herosApiLink}/ordz_hero/v1/followers`, {
      //   hashes: hashArr
      // })

      this.$refs['ComponentModalApeClaimPoints'].claimOpenModal(data)

    },

    // claim points
    async handleClaim () {
      if (!this.gameName) return

      // 没开始玩或者，正在玩 （显示第一步，有输入的邮箱就赋值，到第二步时，登录了地址就赋值）
      // 完成了游戏 （没有登录 第二步，登录了 第三步）

      // 游戏名
      this.cliamGameName = this.gameNameMap[this.gameName]
      this.claimAddress = this.connectWalletInfo.address

      // 没有登录，打开钱包
      if(!this.claimAddress){
        this.$refs.ComponentHeader.openConnectWallet()
        return
      }

      // 检测角色状态
      let nextStatus =  await this.getHeroesList()
      if(!nextStatus){
        return
      }
      // return
      // 如果是小游戏，需要过滤出 已创建的角色
      // cjza
      // this.cliamGameName = 'ordz-pfp-2048'
      // let nameList = ['ordz-pfp-2048', 'ordz-pfp-snake', 'ordz-match']
      // if((nameList.includes(this.cliamGameName)) && !(this.heroeList.length === 1 && this.heroeList[0].token && !this.heroeList[0].hash)) {
      if(!(this.heroeList.length === 1 && this.heroeList[0].token && !this.heroeList[0].hash)) {
        // let hashArr = this.heroeList.map(item => item.hash)
        let hashArr = []
        this.heroeList.forEach(item => {
          if (item.hash) {
            hashArr.push(item.hash)
          }
        })
        let res = await axios.post(`${constans.herosApiLink}/ordz_hero/v1/followers`, {
          hashes: hashArr
        })

        if (res.data.code === 1000) {
          let heros = res.data.data.heros
          if (heros && heros.length) {
            let arr = []
            heros.forEach((item, index) => {
              this.heroeList.forEach((sItem, sIndex) => {
                if (item.hash === sItem.hash) {
                  arr.push({...sItem})
                }
              })
            })
            if (arr.length) {
              this.heroeList = [...arr]
            }
          }
        }

      }
      let data = {
        gameName: this.cliamGameName,
        email: this.cliamEmail || this.myAccountEmail,
        token: this.claimToken,
        address: this.claimAddress,
        currentRole: this.currentRoleInfo,
        followTarget: this.followTarget
      }

      // 获取用户是否使用了分身药水

      // 使用分身药水数量
      let clonePotionCount = 0
      // 最大选择角色数量
      let selectableCount = 0
      let potionRes = await axios.get(`${constans.herosApiLink}/ordz_hero/v1/tools/address/${this.claimAddress}/clone_potion_tool`)
      if (potionRes.data.code === 1000) {
        // cjza
        clonePotionCount = potionRes.data.data.use_count
        selectableCount = clonePotionCount * 5
      }

      // 多选
      if (clonePotionCount) {
        this.$refs['ComponentModalClaimPointsMultiple'].claimOpenModal(data, clonePotionCount, selectableCount)
      // 单选
      } else {
        this.$refs['ComponentModalClaimPoints'].claimOpenModal(data)
      }

    },

    // 处理游戏展示模式
    checkGameDisplayMode(){
      // 处理是否是只有电脑模式
      if(this.onlayPc && this.isMobile){
        let bodyWidth = document.body.clientWidth
        this.gameFrameScale = {transform: `scale(${(bodyWidth-40) / 576})${this.scaleGame ? '!important': ''}`, }
      }
    },

    // 检查所属游戏
    checkGame(hash){
      // hash = 'e36a7801bfc68925c9d9df70c8cc45bcb92070628b26cd411f4a9e64c42683d5i0' //tank
      // hash = '242b24565b0d2f434553c21b21884220f2e99cbd2a2d6871cfe71dc37d6eb7d4i0' //rush
      // hash = 'a8c0babda3b32d252bf4f493999d2e7c5218aa8f9ca64b899186d32c719345cbi0' //rocket
      // [rocket，ordz-merge, ordz-heroes, ordz-match]
      let onlayPcList = ['a8c0babda3b32d252bf4f493999d2e7c5218aa8f9ca64b899186d32c719345cbi0', '030290b1cc28b8f91c27df3be8b13a001ff2e3b8bff8022e0e27fa656ea55410i0','66edac2d9864a3cd323c60d7c7284b49048285cc4700a03f096bf1d821c67156i0', '478153addc6b0d79c1c10c2dcc8c93255cfa8c2e1a3dc3c84f07f0a61cd2648di0',
      'ordz-heroes', 'ordz-pk', ]
      // 只有pc状态
      if(onlayPcList.includes(hash)){
        this.onlayPc = true;
      }

      // 小写ua
      let facility_Model = navigator.userAgent.toLocaleLowerCase()
      let isMobile = facility_Model.match(/mobi/i) || facility_Model.match(/android/i) ||  facility_Model.match(/iphone/i);
      this.isMobile = isMobile;


      // this.gameName = 'ordz-merge'
      // this.gameFrameUrl = `http://localhost:53112/_inscription/ordz-merge`
      // this.inscriptionNumber = '1192300'
      // this.onlayPc = true

      // 处理游戏展示模式
      this.checkGameDisplayMode()

      // if (isMobile) {
      //   // 当前设备是移动设备
      //   if(!isRocket){
      //     // document.body.classList.add('m');
      //     // document.querySelector('.inscription-info').classList.add('mobile');
      //   }else{
      //     // document.body.classList.add('rocket');
      //     // 动态添加手机meta标签
      //     // let head = document.getElementsByTagName('head');
      //     // let meta = document.createElement('meta');
      //     // meta.content = `width=device-width,user-scalable=no,initial-scale=1`
      //     // meta.name = 'viewport';
      //     // head[0].appendChild(meta)
      //   }
      // }
      // console.log('constans=>>>', constans)

      if(this.customGameInscription[hash]){
        let customItem = this.customGameInscription[hash]
        this.gameName = customItem.gameName
        if(hash === 'ordz-heroes'){
          // this.gameFrameUrl = `http://localhost:3400`
          this.gameFrameUrl = `${constans.heroesGameOrigin}`
        }if(hash === 'ordz-pk'){
          this.gameFrameUrl = `http://localhost:3400`
          // this.gameFrameUrl = `${constans.heroesGameOrigin}`
        }else{
          this.gameFrameUrl = `${constans.rocketGameOrigin}/_inscription/${hash}`
          // this.gameFrameUrl = `http://127.0.0.1:8080/${hash}.html`
        }
        this.inscriptionNumber = customItem.inscriptionNumber
      }
      else if(hash === '07f94f1e13890a9b3f5bd127d4a229acbc9888d93e70641a012ceb685d90f3bci0'){
        // 游戏名
        this.gameName = 'ordzRush'
        this.gameFrameUrl = `${constans.rushGameOrigin}/07f94f1e13890a9b3f5bd127d4a229acbc9888d93e70641a012ceb685d90f3bci0`
        this.inscriptionNumber = '13642471';
      }
      else if(this.customGameRouter[hash]){
        let customItem = this.customGameRouter[hash]
        // 游戏名
        this.gameName = customItem.gameName
        this.gameFrameUrl = customItem.url
        this.inscriptionNumber = customItem.inscriptionNumber;
      }
      else{
        axios.get(`${constans.apiUrl}/btc_nft/v1/og_collections/inscription/${hash}`).then(res => {
          res = res.data
          if(res.code !== 1000){
            window.location.href = constans.officialWebsiteUrl
            return
          }
          let { collection_name, inscription } = res.data

          // iframe url
          let hostUrl = ``
          if(collection_name === 'ordz-rush'){
            // 游戏名
            this.gameName = 'ordzRush'
            hostUrl = constans.rushGameOrigin
          } else if(collection_name === 'ordz-games'){
            // 游戏名
            this.gameName = 'ordzCity'
            hostUrl = `${constans.tankGameOrigin}/inscription`;
          }
          this.gameFrameUrl = `${hostUrl}/${hash}`
          this.inscriptionNumber = inscription;
        })
      }

      // 处理游戏展示模式
      this.checkGameDisplayMode()

    },
    // 去除手指放大
    removeTouch(){
      document.documentElement.addEventListener('touchend', function(event) {
        event.preventDefault()
        return
      }, {passive: false})
      document.documentElement.addEventListener('touchstart', function(event) {
        event.preventDefault()
        return
      }, {passive: false})
    },
    // 分享url
    async sharePageUrl(){
      let url = `${location.origin}${location.pathname}`
      if(!!this.myShareCode){
        url += `?og=${this.myShareCode}`
      }
      this.updateGlobalMessage({
        type: 'success',
        message: 'COPIED SHARE URL',
      })
      await navigator.clipboard.writeText(url)

    },
    // Airdrop页面
    toAirdrop(){
      let url = `${constans.officialWebsiteUrl}/airdrop1`
      if(!!this.myShareCode){
        url += `?og=${this.myShareCode}`
      }
      window.open(url)
    },
    // play页面
    toPlay(){
      // window.open(`${constans.officialWebsiteUrl}`)
      location.href = `${location.origin}/ordz-heroes`
    },

    // 游戏提示
    updateGlobalMessage(data){
      this.$showMessage(data.type, data.message)
      // console.log(data)
      // this.globalMessage = data
      // setTimeout(() => {
      //   this.globalMessage.message = ''
      // }, 3 * 1000);
    },
    // 更新钱包
    updateConnectWalletInfo(data){
      this.connectWalletInfo = data
    },

    // 收到子页面消息
    async receiveSonMessage(event){
      let { target, data} = event.data
      if(target === 'game-email'){
        this.cliamEmail = data.value
      }else if(target === 'game-token'){
        this.claimToken = data.value
      }else if(target === 'heroe-switch-heroe'){
        this.currentRoleInfo = target
        // heroes游戏：切换角色
        console.log(target, data)
      }else if(target === 'game-openUrl-message'){
        if(data.type === 'open'){
          window.open(data.url)
        }else{
          location.href = data.url
        }
      } else if (target === 'hero-sign-message') {
        this.getSignatureAndPublic()
      } else if (target === 'hero-pk-sign-message') {
        let nonce_token = await wallet.walletSignMessage()
        this.sendGameMessage({type: 'sign-message-result', data: nonce_token});
      }
    },

    // 钱包签名逻辑（unisat）
    async getSignatureAndPublic() {
      let nonce_token = ''
      let walletype = ''
      let message = ''
      let publicKey = ''
      let signature = ''
      const address = this.connectWalletInfo.address
      console.log(parent.window.unisat, "==============>unisat")
      try {
        const { data } = await axios.get(`${constans.apiUrl}/btc_nft/v1/accounts/${address}/nonce`)
        console.log(data, "================>ressss")
        if (data.code === 1000) {
          const { nonce } = data.data
          publicKey = await window.unisat.getPublicKey();
          signature = await window.unisat.signMessage(nonce);
          message = nonce
          walletype = 'unisat'
          console.log(publicKey, signature, "===========>publicKey, signature")
        }
      } catch (e) {
        console.log('sign message error', e);
        if (e.code && e.code === 4001) {
          walletype = 'unisat'
        }
      }
      let _p = 'iVBORw0KGgoAAAANSUhEUAAAMnkl='
      let ac = window.btoa(`${address}-b-${message}-b-${signature}-b-${publicKey}-b-${walletype}-b-${_p}`);
      let b = (Math.random() + 1).toString(36).substring(2, 8);
      let c = (Math.random() + 1).toString(36).substring(2, 6);
      nonce_token = `${ac.slice(0,8)}${b}${ac.slice(8,13)}${c}${ac.slice(13)}`;
      this.sendGameMessage({type: 'sign-message-result', data: nonce_token});
      // return nonce_token
    },

    // 钱包逻辑
    accountLogin(address){
      let data = {
        code: this.linkShareCode,
        address
      }
      axios.post(`${constans.apiUrl}/btc_nft/v1/cv_accounts/login`, data).then(res => {
        res = res.data
        if(res.code === 1000){
          let { code, email} = res.data
          this.myShareCode = code
          this.myAccountEmail = email

          window.myShareCode = code
        }
      })
    },
    // iframe加载完成
    iframeLoad(){
      this.iframeLoadEd = true;

      this.updateGameEmail()
    },
    // 通知游戏，写入账户邮箱
    updateGameEmail(){
      let gameFrame = document.querySelector('#gameFrame');
      if(!!gameFrame){
        let data = {
          target : 'updateEmail',
          data: {
            email: this.myAccountEmail || ''
          }
        }
        gameFrame.contentWindow.postMessage(data, '*'); // 把父页面数据传给子页面
      }
    },





    /**
     * Heroes
     */
    // 获取可选角色
    async getHeroesList () {
      let promiseList = [
        axios.get(`${constans.herosApiLink}/ordz_hero/v1/heros/${this.claimAddress}`),
        axios.get(`${constans.apiUrl}/btc_nft/v1/cv_accounts/${this.collectionName}/${this.claimAddress}/heroes`),
      ]
      let defaultUserHeroes = null //系统默认角色
      let list = []
      let isFollow = false //有没有follow角色

      try {
        // [获取有没有白板账户，获取有没有购买过nft]
        let resList = await Promise.all(promiseList)
        console.log('xxxx jj', resList)
        let defaultHeroesRes = resList[0].data
        console.log()
        if(defaultHeroesRes.code === 1000){
          let { body_type, hash, token } = defaultHeroesRes.data.hero
          if(!hash){
            try {
              let defaultHeroeInfo = constans.defaultHeroesMapping[body_type || 1]
              defaultUserHeroes = {
                ...defaultHeroeInfo,
                ...defaultHeroesRes.data.hero,
                inscription: defaultHeroeInfo.inscription,
                body_type: body_type || 1
              }
            } catch (error) {
              console.error('error=>>>', error)
            }
          }

          // 判断有没有follow角色
          try {
            let followInfoRes = await axios.get(`${constans.herosApiLink}/ordz_hero/v1/heros/${token}/owner`)
            followInfoRes = followInfoRes.data
            if(followInfoRes.code === 1000){
              isFollow = followInfoRes.data.total_count > 0 //判断是否已经follow用户
              // 跟随的谁 信息
              let followers = followInfoRes.data.followers
              if (followers && followers.length) {
                this.followTarget = followers[0]
              }
            }
          } catch (error) {
            console.error(error)
          }
        }

        let heroesRes = resList[1].data
        // console.log('heroesRes=>>', heroesRes)
        if(heroesRes.code === 1000){
          list = heroesRes.data.heroes
        }
        // console.log('resList=>>>', resList, list)
      } catch (error) {
        console.error('error=>>>', error)
      }

      // cjza
      // let item = {...list[0]}
      // item.inscription = 1
      // let item1 = {...item}
      // item.inscription = 2
      // let item2 = {...item}
      // item.inscription = 3
      // let item3 = {...item}
      // item.inscription = 4
      // let item4 = {...item}
      // item.inscription = 5
      // let item5 = {...item}
      // let heroeList = [...list, item1, item2, item3, item4, item5]

      let heroeList = [...list]

      if(!!defaultUserHeroes && isFollow){
        heroeList.push(defaultUserHeroes)
      }

      // 检测follow 及 角色状态
      if(!heroeList.length){
        // 只有白板 && 也没有follow
        if(!!defaultUserHeroes && !isFollow){
          this.$showMessage('error', 'Choose a Hero to Follow Before Claim')
        }else{
          // 没有角色可以用
          this.$showMessage('error', 'Choose a Hero or Follower to Start')
        }
        return
      }

      console.log('heroeList=>>', heroeList)
      this.heroeList = heroeList
      return true
    },


    /**
     *
     * 以下为移动端game操作
     */
    // 手按下/抬起的时候
    touchAction(options) {
      let { keyType, classNanme} = options
      let name = classNanme.split('-')
      let keyName = ''
      let keyCode = 0
      if (name[1]=== 'button') {
        if(name[0]=== 'left'){
          keyCode = 65
        }else if(name[0]=== 'right') {
          keyCode = 68
        }else if(name[0]=== 'up') {
          keyCode = 87
        }else {
          keyCode = 83
        }
        keyName = name[0]
        // document.querySelector(`.${classNanme} img`).src = `https://play.ordz.games/assets/${keyName}${keyType==='keydown'?'-active':''}.png`
        this[`${keyName}Url`] = this.buttonUrl[`${keyName}${keyType==='keydown'?'Active':''}Url`]
      }else {
        this.ATouchDown = false
        this.BTouchDown = false
        if(name[0] === 'right') {
          keyCode = 'A'
          this.ATouchDown = keyType==='keydown'
        }else {
          keyCode = 'B'
          this.BTouchDown = keyType==='keydown'
        }
        keyName = keyCode
        // document.querySelector(`.${classNanme} img`).src = `https://play.ordz.games/assets/red${keyType==='keydown'?'-active':''}.png`
        this[`red${keyName}Url`] = this.buttonUrl[`red${keyType==='keydown'?'Active':''}Url`]
      }
      // 发送按键操作
      this.sendGameMessage({type: keyType, keyCode, codename : keyName})
    },

    // 更新按钮样式
    updateButtonTouchStatus(options) {
      let { keyType, type} = options
      let keyList = ['up','right','down','left',]
      if(keyList.includes(type)){
        this[`${type}Url`] = this.buttonUrl[`${type}${keyType==='keydown'?'Active':''}Url`]
      }else{
        // console.log(1111, type)
      }
    },

    //获取控件绝对位置
    getAbsolute(dom) {
      let position = {
        left: dom.offsetLeft,
        top: dom.offsetTop,
      }
      while(dom.offsetParent!=null) {
        let oParent = dom.offsetParent;
        position.left += oParent.offsetLeft;
        position.top += oParent.offsetTop;
        dom = oParent;
      }
      return position;
    },

    // 手指移动
    checkJoystickButtonPosition (event){
      event.preventDefault();
      let joystickButton = document.querySelector('.game-joystick-content')
      let joystickMoveButton = document.querySelector('.game-joystick-button')
      let touch = event.changedTouches[0]; //获取第一根手指
      let moveArea = joystickButton.clientWidth / 2; //获取移动区域的一半
      let buttonWidth = joystickMoveButton.clientWidth; //按钮大小
      let buttonPosition = this.getAbsolute(joystickButton); // 获取手指位置
      let moveDistance = { //移动位置
        x: touch.clientX - buttonPosition.left,
        y: touch.clientY - buttonPosition.top,
      }
      // console.log(moveArea, moveDistance)
      //实际偏移位置
      let scaleNum = 0.6; //缩放比例
      let maxTranslate = moveArea - buttonWidth / 2 * scaleNum //最大可移动距离
      let leftTranslate = (moveDistance.x - (buttonWidth / 2)) / scaleNum
      let topTranslate = (moveDistance.y - (buttonWidth / 2)) / scaleNum
      let moveScale = 1; //移动缩放

      // 如果有方向超出区域
      // if(Math.abs(leftTranslate) > maxTranslate || Math.abs(topTranslate) > maxTranslate){
      //   // 计算基础比例
      //   if(Math.abs(leftTranslate) >= Math.abs(topTranslate)){
      //     moveScale = maxTranslate / Math.abs(leftTranslate)
      //   }else{
      //     moveScale = maxTranslate / Math.abs(topTranslate)
      //   }
      // }

      // // 限制最大移动距离
      // if(Math.abs(leftTranslate) > maxTranslate){
      //   leftTranslate = leftTranslate>0 ? maxTranslate : -maxTranslate
      // }
      // if(Math.abs(topTranslate) > maxTranslate){
      //   topTranslate = topTranslate>0 ? maxTranslate : -maxTranslate
      // }

      joystickMoveButton.style.transform = `scale(${scaleNum}) translate(${leftTranslate}px, ${topTranslate}px)`
      // console.log(moveScale, maxTranslate)
      /*
        *计算移动方向
      */
      let minMovePosition = buttonWidth/10; //最小移动距离
      let movePosition = {
        x: moveDistance.x - (buttonWidth/2),
        y: moveDistance.y - (buttonWidth/2),
      }

      // [坦克]修改为单方向操作
      if(window.gameName === 'ordzCity' || window.gameName === 'ordz-snake'){
        // 判断哪个方向滑动距离远，重置另一个方向
        if(Math.abs(movePosition.x)>= Math.abs(movePosition.y)){
          movePosition.y = 0
        }else{
          movePosition.x = 0
        }
      }
      // console.log(Math.abs(movePosition.x), Math.abs(movePosition.y))

      let touchStatus = {
        top: false,
        right: false,
        bottom: false,
        left: false,
      }
      // 计算左右
      if(movePosition.x > 0 && movePosition.x >= minMovePosition){
        touchStatus.right = true
      }else if(movePosition.x < 0 && movePosition.x <= -minMovePosition){
        touchStatus.left = true
      }
      // 计算上下
      if(movePosition.y > 0 && movePosition.y >= minMovePosition){
        touchStatus.bottom = true
      }else if(movePosition.y < 0 && movePosition.y <= -minMovePosition){
        touchStatus.top = true
      }
      return touchStatus
    },

    joystickTouchStart(event){
      let touchStatus = this.checkJoystickButtonPosition(event)
      this.sendMoveMessage(touchStatus)
      // console.log(touchStatus)
    },
    joystickTouchMove(event){
      let touchStatus = this.checkJoystickButtonPosition(event)
      this.sendMoveMessage(touchStatus)
      // console.log(touchStatus)
    },
    joystickTouchEnd(event){
      event.preventDefault();
      // let joystickButton = document.querySelector('.game-joystick-content')
      let joystickMoveButton = document.querySelector('.game-joystick-button')
      joystickMoveButton.style.transform = `scale(.6)  translate(0, 0)`

      this.sendMoveMessage({
        top: false,
        right: false,
        bottom: false,
        left: false,
      })
    },

    // 发送移动消息
    sendMoveMessage(touchStatus) {
      // 初始化按钮对应的键盘code
      let keyCodeMapping = {
        top: 87,
        bottom: 83,
        left: 65,
        right: 68,
      }
      // 命名有问题，重置对应的方向
      let touchButtonName = {
        top: 'up',
        bottom: 'down',
        left: 'left',
        right: 'right',
      }
      Object.keys(touchStatus).forEach(type => {
        let keyType = touchStatus[type] ? 'keydown' : 'keyup'
        // console.log(222, type, touchButtonName[type])
        this.updateButtonTouchStatus({
          keyType,
          type: touchButtonName[type]
        })
        // rush AB按钮都是跳
        if(window.gameName === 'ordzRush' && !touchStatus[type] && type === 'top' && (this.ATouchDown || this.BTouchDown)){
          keyType = 'keydown'
        }
        let keyCode = keyCodeMapping[type]
        // 发送按键操作
        this.sendGameMessage({type: keyType, keyCode, codename : type})
      })

      // this.sendGameMessage({type: this.ATouchDown ? 'keydown' : 'keyup', keyCode: 'A', codename : 'A'})
      // this.sendGameMessage({type: this.BTouchDown ? 'keydown' : 'keyup', keyCode: 'B', codename : 'B'})
    },

    // 发送按键操作
    sendGameMessage(info) {
      // console.log(info)
      let iframeDom = document.querySelector('#gameFrame')
      iframeDom.contentWindow.postMessage(info,'*'); // 把父页面数据传给子页面
    },
    // 跳转到排行榜
    toApeRushLeaderboard(){
      window.open(`${constans.officialWebsiteUrl}/ordz-rush/ape-rush-leaderboard`)
    }

  },
};
</script>

<style scoped lang="scss">
  @import "./index.scss";
</style>
