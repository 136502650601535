<template>
  <div class="component-scan-qrcode-wrapper">
    <div class="modal-close" @click="closeScanQrcode()" @touchstart="closeScanQrcode()"></div>
    <video id="video"></video>
    <canvas ref="canvas" id="canvas"/>
  </div>
</template>

<script>
import dayjs from 'dayjs'
  export default {
    data () {
      return {
        video: null
      }
    },
    computed: {

    },
    mounted () {
      // 启动检查
      this.startCheckQrcode()
    },
    methods: {
      // 关闭扫码
      closeScanQrcode(){
        if(this.video && this.video.pause){
          this.video.pause()
        }
        this.$emit('closeScanQrcode', false)
      },
      // 检查是否是钱包
      checkCodeIsAddress (address) {
        address = address.toLowerCase()
        let isBc1 = address.indexOf('bc1') === 0 //bc1q,bc1p
        let is397 = address.indexOf('397') === 0
        let is1ak = address.indexOf('1ak') === 0
        return isBc1 || is397 || is1ak
      },
      // 启动检查
      startCheckQrcode(){
        let _this = this;
        try {
          _this.video = document.querySelector('#video');
          var canvasElement = document.getElementById("canvas");
          var canvas = canvasElement.getContext("2d");

          function drawLine(begin, end, color) {
            canvas.beginPath();
            canvas.moveTo(begin.x, begin.y);
            canvas.lineTo(end.x, end.y);
            canvas.lineWidth = 4;
            canvas.strokeStyle = color;
            canvas.stroke();
          }
          // 申请视频权限
          navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((stream) => {
            // 拿到数据流
            _this.video.srcObject = stream;
            _this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
            _this.video.play();
            requestAnimationFrame(tick);
          }).catch(error => {
            // 没有权限
            this.closeScanQrcode()
            this.$emit('updateGlobalMessage', {
              message: 'Please check camera permissions',
              type: 'error',
            })
          });

          function tick() {
            if (_this.video.readyState === _this.video.HAVE_ENOUGH_DATA) {
              canvasElement.hidden = false;

              canvasElement.height = _this.video.videoHeight;
              canvasElement.width = _this.video.videoWidth;
              canvas.drawImage(_this.video, 0, 0, canvasElement.width, canvasElement.height);
              var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
              let code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
              })
              if (code) {
                drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
                // console.log(code.data)
                // 解密扫码
                let scanData = ''
                try {
                  scanData = window.atob(code.data)
                } catch (error) {
                  console.error(error)
                }
                // 检测是否是bc1钱包
                if(_this.checkCodeIsAddress(scanData)){
                  localStorage.setItem('last-login-address', scanData)
                  localStorage.setItem('last-login-address-expired', dayjs().add(7, 'd').unix())
                  _this.$emit('updateConnectWalletInfoMutation',{
                    type: 'mobile',
                    address: scanData,
                    balance: 0
                  })
                  _this.video.pause()
                }
              }
            }
            requestAnimationFrame(tick);
          }
        } catch (error) {
          // 没有权限
          this.closeScanQrcode()
          this.$emit('updateGlobalMessage', {
            message: 'Please check camera permissions',
            type: 'error',
          })
        }
      },


    },
  }
</script>

<style lang="scss" scoped>
  .component-scan-qrcode-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    background: #000;
    display: flex;
    align-items: center;
    #canvas{
      display: none;
    }

    .modal-close {
      margin-right: -5px;
      width: 30px;
      height: 30px;
      display: inline-block;
      position: fixed;
      top: 28px;
      right: 30px;
      z-index: 2;
      transform-origin: center;
      transform: rotate(45deg);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10%;
        height: 66%;
        background: #fff;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 66%;
        height: 10%;
        background: #fff;
      }
    }
  }
</style>
