import { getAddress, signTransaction, signMessage } from 'sats-connect'
import Day from 'dayjs'
import store from '@/store'
import Vue from "vue";
import axios from "axios";
import { apiUrl } from "@/config/constans";
import { cookies } from '@/utils'

const getAddressOptions = {
  payload: {
    purposes: ['ordinals', 'payment'],
    message: 'Address for receiving Ordinals and payments',
    network: {
      type:'Mainnet'
    },
  },
  onFinish: (response) => {
    console.log(response)
  },
  onCancel: () => alert('Request canceled'),
}

// wallet signMessage
const walletSignMessage = async () => {
  let nonce_token = ''
  try {
    const { data } = await axios.get(`${apiUrl}/btc_nft/v1/accounts/bc1qg8450cxpnnfvx26mjkswpcy4ajr7wvd50cfvk7/nonce`)
    // 标记
    // const { data } = await axios.get(`${apiUrl}/btc_nft/v1/accounts/${store.state.connectWalletInfo.address}/nonce`)
    if (data.code === 1000) {
      const { nonce } = data.data
      let result;
      let loginWalletType = cookies.get('last-connected-wallet')
      if (loginWalletType === 'unisat') {
        result = await unisatSignMessage(nonce)
      } else if (loginWalletType === 'xverse') {
        result = await xverseSignMessage(nonce)
      } else if (loginWalletType === 'okx') {
        result = await okxSignMessage(nonce)
      } else if (loginWalletType === 'bitget') {
        result = await bitgetSignMessage(nonce)
      } else if (loginWalletType === 'hiro') {
        result = await hiroSignMessage(nonce)
      } else {
        Vue.prototype.$showMessage('error', 'Please use unisat/xverse/OKX wallet login.')
      }
      if (!!result) {
        const { walletType, address, publicKey, signature, message } = result
        let _p = 'iVBORw0KGgoAAAANSUhEUAAAMnkl='
        let ac = window.btoa(`${address}-b-${message}-b-${signature}-b-${publicKey}-b-${walletType}-b-${_p}-b-${store.state.connectWalletInfo.address}`);
        let b = (Math.random() + 1).toString(36).substring(2, 8);
        let c = (Math.random() + 1).toString(36).substring(2, 6);
        nonce_token = `${ac.slice(0,8)}${b}${ac.slice(8,13)}${c}${ac.slice(13)}`;
      }
    } else {
      Vue.prototype.$showMessage('error', data.message)
    }
  } catch (e) {
    console.log('sign message error', e)
  } finally {
    return nonce_token
  }
}

// xverse signMessage
const xverseSignMessage = async message => {
  return new Promise(async (resolve) => {
    const address = store.state.connectWalletVerifyAddress
    const publicKey = store.state.connectWalletPublicKey
    const signOption = {
      address,
      message,
      onFinish: (signature) => {
        resolve({address, publicKey, signature, message, walletType: 'xverse'})
      },
      onCancel: () => resolve(null)
    }
    try {
      await signMessage(signOption)
    } catch (e) {
      console.log('xverse sign message error', e)
      resolve(null)
    }
  })
}

// unisat signMessage
const unisatSignMessage = async message => {
  try {
    let publicKey = store.state.connectWalletPublicKey
    let signature = await window.unisat.signMessage(message)
    let address = store.state.connectWalletVerifyAddress
    return { publicKey, signature, address, message, walletType: 'unisat' }
  } catch (e) {
    console.log('unisat sign message error', e)
    return null
  }
}

// okx signMessage
const okxSignMessage = async message => {
  try {
    const address = store.state.connectWalletVerifyAddress
    const publicKey = store.state.connectWalletPublicKey
    const signature = await window.okxwallet.bitcoin.signMessage(
      message,
      {
        from: address
      }
    )
    return { publicKey, address, signature, message, walletType: 'okx' }
  } catch (e) {
    console.log('okx sign message error', e)
    return null
  }
}

// bitget signMessage
const bitgetSignMessage = async message => {
  try {
    let publicKey = store.state.connectWalletPublicKey
    let signature = await window.bitkeep.unisat.signMessage(message)
    let address = store.state.connectWalletVerifyAddress
    return { publicKey, signature, address, message, walletType: 'bitget' }
  } catch (e) {
    console.log('bitget sign message error', e)
    return null
  }
}

// hiro signMessage
const hiroSignMessage = async message => {
  try {
    let signParam = {message}
    const address = store.state.connectWalletVerifyAddress
    const publicKey = store.state.connectWalletPublicKey
    if (address.startsWith('bc1p')) {
      signParam.paymentType = 'p2tr'
    }
    const req = await window.btc.request('signMessage', signParam);
    return {publicKey, address, signature: req.result.signature, message, walletType: 'hiro'}
  } catch (e) {
    console.log('hiro sign message error', e)
    return null
  }
}

// 连接 xverse 钱包
const xverseConnect = (parentThis, callback) => {
  // 更新连接状态
  parentThis.updateConnectWalletStateMutation('connecting')
  let timer = setTimeout(async () => {
    try {

      clearTimeout(timer)
      let history = JSON.parse(cookies.get('xverse-me-connection') || '{}')
      // 本地记录还未过期
      if (history && (history.expiry > Day().unix())) {
        callback({
          state: 'success',
          address: history?.value || '',
          balance: 0,
          xverseReconnect: true
        })
        // 更新签名所需要的地址和publicKey
        const [ordinalsAddress, paymentAddress] = history.value
        // 更新链接签名验证地址
        store.commit('updateConnectWalletVerifyAddress', paymentAddress.address)
        // 更新签名公钥
        store.commit('updateConnectWalletPublicKey', paymentAddress.publicKey)
        // 更新连接状态
        parentThis.updateConnectWalletStateMutation('connected')
        // 存储记录
        cookies.set('last-connected-wallet', 'xverse')
        // 已过期，重新签名
      } else {
        cookies.remove('xverse-me-connection')
        // 连接钱包
        await getAddress({
          ...getAddressOptions,
          onFinish: res => {
            callback({
              state: 'success',
              address: res?.addresses,
              balance: 0
            })
            const [ordinalsAddress, paymentAddress] = res.addresses
            // console.log(paymentAddress, ordinalsAddress, "=============>addresss")
            // // 更新连接状态
            // parentThis.updateConnectWalletStateMutation('connected')
            // 更新链接签名验证地址
            store.commit('updateConnectWalletVerifyAddress', paymentAddress.address)
            // 更新签名公钥
            store.commit('updateConnectWalletPublicKey', paymentAddress.publicKey)
            // // 存储记录
            // localStorage.setItem('last-connected-wallet', 'xverse')
            // localStorage.setItem('xverse-me-connection', JSON.stringify({
            //   expiry: Day().add(30, 'm').unix(),
            //   value: res?.addresses || null
            // }))
          },
          onCancel: () => {
            callback({
              state: 'cancel',
              message: 'Could not connect to Xverse wallet, user cancelled'
            })
            // 更新连接状态
            parentThis.updateConnectWalletStateMutation('not')
          }
        })
      }

    } catch (e) {
      callback({
        state: 'error',
        message: 'No Bitcoin Wallet installed'
      })
      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('not')

    }

  }, 2 * 1000)

}

// 连接 unisat 钱包
const unisatConnect = async (parentThis, callback) => {

  // check 是否有下载 unisat 钱包插件
  if (typeof window.unisat === 'undefined') {
    callback({
      state: 'notInstall',
      message: 'No Bitcoin Wallet installed'
    })
    // 更新连接状态
    parentThis.updateConnectWalletStateMutation('not')
    return
  }

  // check 连接的网络 (livenet and testnet)
  // let network = await window.unisat.getNetwork()
  // if (network === 'testnet') {
  //   try {
  //     await window.unisat.switchNetwork("livenet")
  //   } catch (e) {
  //     // 拒绝切换到主网，不登录
  //     parentThis.updateConnectWalletStateMutation('not')
  //     return
  //   }
  // }

  // 更新连接状态
  parentThis.updateConnectWalletStateMutation('connecting')
  let timer = setTimeout(async () => {
    try {
      clearTimeout(timer)

      // 连接钱包
      let res = await window.unisat.requestAccounts()
      // 获取公钥
      let publickey = await window.unisat.getPublicKey();
      // 获取余额
      let data = unisat.getBalance()

      const { total } = data
      let balance = total / 100000000

      // 成功回调
      callback({
        state: 'success',
        address: res[0] || '',
        // address: 'bc1qltk826kraf7rqdm6yqaa7evcwr5gss7v62fssd',
        // address: 'bc1quysfqkpvr9syvrxezhlnfekyjxwdgjty9asg0h',
        // address: 'bc1qz0y9xhmky3mdrkzyftvy3xu4jm6vvsh850ahcv',
        balance
      })

      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('connected')
      // 更新签名公钥
      store.commit('updateConnectWalletPublicKey', publickey)
      // 更新链接签名验证地址
      store.commit('updateConnectWalletVerifyAddress', res[0] || '')
      // 存储记录
      cookies.set('last-connected-wallet', 'unisat')

    } catch (e) {
      if (e.code === 4001) {
        callback({
          state: 'error',
          message: 'Could not connect to Unisat wallet, user cancelled'
        })
      } else {
        callback({
          state: 'error',
          message: e.message || 'error'
        })
      }
      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('not')
    }
  }, 2 * 1000)

}

// 连接 bitget 钱包
const bitgetConnect = async (parentThis, callback) => {

  // check 是否有下载 bitget 钱包插件
  if (typeof window.bitkeep === 'undefined' || typeof window.bitkeep.unisat === 'undefined') {
    callback({
      state: 'notInstall',
      message: 'No Bitcoin Wallet installed'
    })
    // 更新连接状态
    parentThis.updateConnectWalletStateMutation('not')
    return
  }

  // 更新连接状态
  parentThis.updateConnectWalletStateMutation('connecting')
  let timer = setTimeout(async () => {
    try {
      clearTimeout(timer)

      // 连接钱包
      let res = await window.bitkeep.unisat.requestAccounts()
      // 获取公钥
      let publickey = await window.bitkeep.unisat.getPublicKey();
      // 获取余额
      let data = window.bitkeep.unisat.getBalance()

      const { total } = data
      let balance = total / 100000000

      // 成功回调
      callback({
        state: 'success',
        address: res[0] || '',
        // address: 'bc1qltk826kraf7rqdm6yqaa7evcwr5gss7v62fssd',
        // address: 'bc1quysfqkpvr9syvrxezhlnfekyjxwdgjty9asg0h',
        // address: 'bc1qz0y9xhmky3mdrkzyftvy3xu4jm6vvsh850ahcv',
        balance
      })

      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('connected')
      // 更新签名公钥
      store.commit('updateConnectWalletPublicKey', publickey)
      // 更新链接签名验证地址
      store.commit('updateConnectWalletVerifyAddress', res[0] || '')
      // 存储记录
      cookies.set('last-connected-wallet', 'bitget')

    } catch (e) {
      if (e.code === 4001) {
        callback({
          state: 'error',
          message: 'Could not connect to Unisat wallet, user cancelled'
        })
      } else {
        callback({
          state: 'error',
          message: e.message || 'error'
        })
      }
      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('not')
    }
  }, 2 * 1000)

}

// 连接 okx 钱包
const okxConnect = (parentThis, callback) => {
  if (typeof window.okxwallet === 'undefined') {
    callback({
      state: 'notInstall',
      message: 'No Bitcoin Wallet installed'
    })
    // 更新连接状态
    parentThis.updateConnectWalletStateMutation('not')
    return
  }
  // 更新连接状态
  parentThis.updateConnectWalletStateMutation('connecting')
  let timer = setTimeout(async () => {
    clearTimeout(timer)

    // 连接钱包
    window.okxwallet.bitcoin.connect().then(res => {
      console.log(res, "===========>okx wallet res")

      const { address, compressedPublicKey, publicKey } = res

      // 成功回调
      callback({
        state: 'success',
        address: res.address || ''
      })

      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('connected')
      // 更新公钥
      store.commit('updateConnectWalletPublicKey', compressedPublicKey)
      // 更新链接签名验证地址
      store.commit('updateConnectWalletVerifyAddress', res.address || '')
      // 存储记录
      cookies.set('last-connected-wallet', 'okx')
    }).catch(e => {
      if (e.code === 4001) {
        callback({
          state: 'error',
          message: 'Could not connect to Okx wallet, user cancelled'
        })
      } else {
        callback({
          state: 'error',
          message: e.message || 'error'
        })
      }
      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('not')
    })
  }, 2 * 1000)
}

// 连接 hiro 钱包
const hiroConnect = (parentThis, callback) => {
  console.log(window.HiroWalletProvider, 'hiro wallet connected')
  if (typeof window.HiroWalletProvider === 'undefined') {
    callback({
      state: 'notInstall',
      message: 'No Bitcoin Wallet installed'
    })
    // 更新连接状态
    parentThis.updateConnectWalletStateMutation('not')
    return
  }
  // 更新连接状态
  parentThis.updateConnectWalletStateMutation('connecting')
  let timer = setTimeout(async () => {
    clearTimeout(timer)

    // 超时重置按钮状态
    const connectWalletInfo = store.state.connectWalletInfo
    // 连接超时重置状态  避免 hiro 钱包不授权直接关闭授权弹窗出现的按钮状态问题
    let connectStateTimer = setTimeout(() => {
      // 没有账号信息（第一次登录连接钱包）
      if (!connectWalletInfo.address && !connectWalletInfo.type) {
        // 更新连接状态
        parentThis.updateConnectWalletStateMutation('not')
      } else{
        // 有账号信息（切换钱包）
        // 更新连接状态
        parentThis.updateConnectWalletStateMutation('connected')
      }
    }, 10 * 1000)

    // 连接钱包
    window.btc?.request('getAddresses').then(res => {
      console.log(res, "===========>hiro wallet res")
      const { result: { addresses } } = res
      // 授权之后清空超时检测
      if (connectStateTimer) clearTimeout(connectStateTimer)
      // 成功回调
      callback({
        state: 'success',
        address: addresses
      })

      // // 更新连接状态
      // parentThis.updateConnectWalletStateMutation('connected')
      // // 更新公钥
      // store.commit('updateConnectWalletPublicKey', '')
      // // 更新链接签名验证地址
      // store.commit('updateConnectWalletVerifyAddress', addresses[0].address || '')
      // // 存储记录
      // localStorage.setItem('last-connected-wallet', 'hiro')
    }).catch(e => {
      if (e.code === 4001) {
        callback({
          state: 'error',
          message: 'Could not connect to Okx wallet, user cancelled'
        })
      } else {
        callback({
          state: 'error',
          message: e.message || 'error'
        })
      }
      // 更新连接状态
      parentThis.updateConnectWalletStateMutation('not')
    })
  }, 2 * 1000)
}

// unisat 钱包 发送 btc
const unisatSendBitcoin = async (callback, toAddress, satoshis, feeRate) => {
  try {

    // check 是否有下载 unisat 钱包插件
    if (typeof window.unisat === 'undefined') {
      callback({
        state: 'notInstall',
        message: 'No Bitcoin Wallet installed'
      })
      return
    }

    // check 连接的网络 (livenet and testnet)
    let network = await window.unisat.getNetwork()
    if (network === 'testnet') {
      try {
        await window.unisat.switchNetwork("livenet")
      } catch (e) {
        // 拒绝切换到主网，不登录
        store.commit('updateConnectWalletStateMutation', 'not')
        return
      }
    }

    // 触发 send
    const txid = await window.unisat.sendBitcoin(
      toAddress,
      satoshis
      // !!feeRate && {feeRate}
    )

    // 成功回调
    callback({
      state: 'success',
      txid: txid
    })

    // 失败回调
  } catch (e) {

    callback({
      state: 'error',
      message: e.message
    })

  }
}

// 退出 钱包
const signOut = (parentThis) => {
  parentThis.updateConnectWalletInfoMutation({
    type: null,
    address: '',
    balance: 0
  })
  parentThis.updateConnectWalletStateMutation('not')
  // 更新公钥
  store.commit('updateConnectWalletPublicKey', '')
  // 更新链接签名验证地址
  store.commit('updateConnectWalletVerifyAddress', '')
  // 清理记录
  localStorage.removeItem('last-connected-wallet')
  localStorage.removeItem('xverse-me-connection')
}

// 给游戏页面发送消息
const sendToGameMessage = (data) => {
  let gameFrame = document.querySelector('#gameFrame');
  if(!!gameFrame){
    // let data = {
    //   target : 'updateConnectWalletInfo',
    //   data: {
    //     info: {
    //       type: null,
    //       address: '',
    //       balance: 0
    //     }
    //   }
    // }
    gameFrame.contentWindow.postMessage(data, '*'); // 把父页面数据传给子页面
  }
}

export default {
  walletSignMessage,
  xverseConnect,
  unisatConnect,
  okxConnect,
  hiroConnect,
  bitgetConnect,
  signOut,
  sendToGameMessage
}
