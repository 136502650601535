<template>
  <!-- Modal - claim -->
  <div class="modal-mask" v-show="claimModalVisible">
    <div class="modal-content claim">

      <!-- Header - close  -->
      <div class="modal-close" @click="claimCloseModal()" @touchstart="claimCloseModal"></div>
      <!-- Header - title -->
      <h6 class="modal-title">CLAIM POINTS</h6>

      <!-- 角色列表 -->
      <template v-if="claimModalType !== 'history'">
        <div class="heroes-list" v-if="showSelectHeroes">

          <!-- pc -->
          <template v-if="!isMobile">
            <div :class="[`${optionalList.length <= 4 ? '' : 'scroll-content reset-scrollbar'}`]">

              <!-- Left -->
              <div class="common-hover-status-color-opacity arrows-icon left"
                  v-show="heroePageIndex > 0"
                  @click="setHeroePageIndex(heroePageIndex-1, paginationActive.prev)">
                {{ '<' }}
              </div>

              <!-- 图片列表 -->
              <div :class="[`role-list move-transition ${optionalList.length === 1 ? 'only-one' : ''} ${optionalList.length <= 4 ? 'flex-content' : ''}`]"
                :style="{minWidth: `calc(100% * ${paginationOptionalList.length})`, transform: `translateX(-${heroePageIndex * 100/paginationOptionalList.length}%)`}"
              >
                <div class='item-list' v-for="(itemList, listIndex) in paginationOptionalList" :key="listIndex">
                  <div :class="[`role-item global-img-mask ${selectInscriptionId === item.inscription ? 'select' : ''}`]"
                    v-for="(item, index) in itemList" :key="`${item.inscription}-${index}`"
                    @click="setSelectInscriptionId(item.inscription)"
                  >
                    <div :class="`role-img ${item.origin === 'help' ? 'help' : ''}`">
                      <div class='img-list'>
                        <template v-for="(_type) in getObjectKeys(item.roleImg)" >
                          <img :key="_type" :src="`${constans.herosImgOrigin}/${_type}/${item.roleImg[_type]}.png`" />
                        </template>
                      </div>
                    </div>
                    <!-- <div class='role-id'>#{{item.inscription || ''}}</div> -->
                    <div class="role-info-box">
                      <div class="role-info" :class="{'follower': !item.hash}" v-if="selectInscriptionId === item.inscription">
                        <p v-if="item.hash" class="role-info-title">ORDZ HERO {{ item.name }}</p>
                        <p v-if="!item.hash" class="role-info-title">Follower</p>
                        <p v-if="item.hash" class="role-info-subtitle">INSCRIPTION #{{ item.inscription }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Right -->
              <div class="common-hover-status-color-opacity arrows-icon right"
                  v-show="heroePageIndex < (paginationOptionalList.length - 1)"
                  @click="setHeroePageIndex(heroePageIndex+1, paginationActive.next)">
                {{ '>' }}
              </div>

            </div>
            <!-- 切换分页 -->
            <!-- <div class='heroe-pagination' v-if="optionalList.length > 4">
              <div :class="[`pagination-icon prev ${!paginationActive.prev ? 'disabled' : ''}`]"
                @click="setHeroePageIndex(heroePageIndex-1, paginationActive.prev)"
              >{{ '<' }}</div>
              <div class='pagination-number'>{{heroePageIndex+1}}/{{paginationOptionalList.length}}</div>
              <div :class="[`pagination-icon next ${!paginationActive.next ? 'disabled' : ''}`]"
                @click="setHeroePageIndex(heroePageIndex+1, paginationActive.next)"
              >{{ '>' }}</div>
            </div> -->
          </template>

          <!-- mobile -->
          <div class='mobile-select-nft hidden-scrollbar' v-else>
            <div :class="[`start-list ${optionalList.length <=3 ? 'large-height' : ''}`]">
              <div :class="[`role-item global-img-mask ${selectInscriptionId === item.inscription ? 'select' : ''}`]"
                v-for="(item, index) in optionalList"
                :key="`${item.inscription}-${index}`"
                @click="setSelectInscriptionId(item.inscription)"
                @touchstart="setSelectInscriptionId(item.inscription)"
              >
                <div class='role-img'>
                  <div class='img-list'>
                    <template v-for="(_type) in getObjectKeys(item.roleImg)" >
                      <img :key="_type" :src="`${constans.herosImgOrigin}/${_type}/${item.roleImg[_type]}.png`" />
                    </template>
                  </div>
                </div>
                <!-- <div class='role-id'>#{{item.inscription || ''}}</div> -->
                <div class="role-info-box">
                  <div class="role-info" :class="{'follower': !item.hash}" v-if="selectInscriptionId === item.inscription">
                    <p v-if="item.hash" class="role-info-title">{{ item.name }}</p>
                    <p v-if="!item.hash" class="role-info-title">Follower</p>
                    <p v-if="item.hash" class="role-info-subtitle">#{{ item.inscription }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 搜索代打角色 -->
          <!-- <div class="search-hero-box" v-if="claimModalType === 'token'">
            <ComponentLoading v-if="isSearching" :size="18" :borderWidth="2"/>
            <input v-else v-model="searchKeywords" class="search-hero-input" type="text" placeholder="search by # or inscription" @keydown="searchKeydown">
          </div> -->

        </div>
        <div class="picture-preview gif-game" v-else>
          <img src="@/assets/img/game-rush-logo.gif" alt="">
        </div>
     </template>

      <!-- type token -->
      <template v-if="claimModalType === 'token'">
        <div style="width: 100%">
        <div class="normal-input">
          <input v-model="claimForm.email" :disabled="isShowCode" type="text" placeholder="Your Email Reserved in Game">
        </div>

        <div class="normal-input">
          <input v-model="claimForm.token" :disabled="isShowCode" type="text"  placeholder="Your Token Copied in Game">
        </div>

        <div v-if="isShowCode" class="special-input">
          <div class="claim-input">
            <input v-model="claimForm.code" type="text"  placeholder="Email Verification Code">
          </div>
          <button v-if="isCodeLoading" class="common-hover-status-bg loading" disabled >
            <div class="modal-loading-icon"></div>
          </button>
          <button v-else class="common-hover-status-color" :disabled="emailCodeNum !== 60 || !claimForm.email" @click="getEmailCode('claim')" @touchstart="getEmailCode">
            {{ emailCodeNum === 60 ? 'CODE' : emailCodeNum }}
          </button>
        </div>

        <!-- 选择游戏 -->
        <!-- <div class="selector-game" @click.stop="toggleGameSelector(!gameSelectorVisible)" @touchstart.stop="toggleGameSelector(!gameSelectorVisible)">
          <div class="selector-game-content" v-if="claimHeroesGameName.value">{{ claimHeroesGameName.label }}</div>
          <div class="selector-game-content placeholder" v-else>Select the game</div>
          <i class="iconfont icon-xiala" :class="{'active': gameSelectorVisible}"></i>
          <ul class="selector-options" :style="gameSelectorVisible && `height: ${selectHeroesOptions.length * 35}px;`" @click.stop>
            <li class="selector-options-item" v-for="(item, index) in selectHeroesOptions" :key="index" @click="selectGame(item)" @touchstart.stop="selectGame(item)">{{ item.label }}</li>
          </ul>
        </div> -->

        <div class="btn-box">
          <!-- cliam -->
          <button v-if="!isClaimLoading" class="modal-btn common-hover-status-bg" :disabled="!claimButtonStatus" @click="handleClaim()" @touchstart="handleClaim">CLAIM</button>
          <button v-else class="modal-btn common-hover-status-bg loading"><i></i><span>checking</span></button>
          <!-- check -->
          <button v-if="!isCheckLoading" class="modal-btn common-hover-status-bg" @click="handleHistory()" @touchstart="handleHistory">CHECK</button>
          <button v-else class="modal-btn common-hover-status-bg loading"><i></i><span>checking</span></button>
        </div>

        <!-- <div class="special-input">
          <div class="claim-input">
            <input v-model="claimForm.token" type="text"  placeholder="Your Token Copied in Game">
          </div>
          <button class="common-hover-status-bg" :disabled="(!claimForm.email || !claimForm.token)" @click="handleClaim()">CLAIM</button>
        </div> -->
        </div>
      </template>

      <!-- type confirm -->
      <template v-else-if="claimModalType === 'confirm'">
        <div style="width: 100%">

        <!-- <p class="score"><span>You will get: Playing Stage {{ claimLevel }} - {{ claimScore }} points</span></p> -->
        <!-- <p class="score">The Owner of Stage {{ claimLevel }} will get {{ claimScore * 0.25 }} points</p> -->
        <!-- <p class="score">Note: every stage can only claim once a day</p>   -->

        <template v-if="isFollower">
          <!-- <p class="score">You have Collected <span>{{ renderInfoOfConfirm.holder.money_plus }} Ordz and {{ renderInfoOfConfirm.holder.exp_plus }} Power</span></p> -->
          <!-- <p class="score">Hero Can Claim <span>{{ renderInfoOfConfirm.holder.money_plus }} Ordz and {{ renderInfoOfConfirm.holder.exp_plus }} Power</span></p> -->
          <p class="score">- {{ renderInfoOfConfirm.followed.follower_points_ratio * 100 }}% {{ renderInfoOfConfirm.followed.money_plus }} Ordz and {{ renderInfoOfConfirm.followed.exp_plus }} Power for Hero #{{ followTarget.owner_name }}</p>
          <p class="score"><span>- {{ renderInfoOfConfirm.followed.holder_points_ratio * 100 }}% {{ renderInfoOfConfirm.holder.money_plus }} Ordz and {{ renderInfoOfConfirm.holder.exp_plus }} Power for Yourself</span></p>
        </template>

        <template v-else>
          <!-- <p class="score">You have Collected <span>{{ renderInfoOfConfirm.holder.money_plus }} Ordz and {{ renderInfoOfConfirm.holder.exp_plus }} Power</span></p> -->
          <p class="score">Hero Can Claim <span>{{ renderInfoOfConfirm.holder.money_plus }} Ordz and {{ renderInfoOfConfirm.holder.exp_plus }} Power</span></p>
        </template>

        <p class="score">Note: Each Hero Can only claim 3 times a day</p>



        <div class="modal-module-info" style="width: 100%;margin-bottom: 0;">Receiving Address:</div>
        <div class="normal-input">
          <input v-model="claimForm.address" :disabled="!isFirstClaim" type="text" placeholder="Your BTC Address Starting with 'bc1'">
        </div>
        <div class="address-error-tips" v-if="claimForm.address && !checkBc1Address(claimForm.address)">Only addresses starting with "bc1" are supported</div>

        <div class="verification-box" v-if="isShowVerification">
          <ComponentVerification @successCallback="claimVerificationCallback" />
        </div>

        <!-- <button class="common-hover-status-bg" :disabled="!claimForm.address" @click="confirmClaim()">CONFIRM</button> -->
        <button v-if="!isClaimLoading" class="modal-btn common-hover-status-bg"
          :class="(claimForm.address && checkBc1Address(claimForm.address)) && 'active'"
          @click="claimShowVerification(true)" @touchstart="claimShowVerification"
        >Claim Now</button>
        <button v-else class="modal-btn common-hover-status-bg loading"><i></i><span>checking</span></button>
        </div>
      </template>

      <!-- type success -->
      <template v-else-if="claimModalType === 'success'">
        <div style="width: 100%">

        <!-- <div class="claim-success-row" v-if="claimSuccessData.money_plus">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score"><span>Total Ordz Earned Today: {{ claimSuccessData.money_plus }}</span></p>
        </div>

        <div class="claim-success-row" v-if="claimSuccessData.exp_plus">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score"><span>Total Power Earned Today: {{ claimSuccessData.exp_plus }}</span></p>
        </div> -->

        <div class="claim-success-row">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score">Total Hero Earn Today: <span>{{ claimSuccessData.money_plus }} Ordz and {{ claimSuccessData.exp_plus }} Power</span></p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Base Points: {{ claimSuccessInfo.ratios.money.base | floorNumber }} Ordz and {{ claimSuccessInfo.ratios.exp.base | floorNumber }} Power</p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Holder Bonus ratio: {{ claimSuccessInfo.ratios.wallet_score_ratio }}x</p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Hero Bonus ratio: {{ (claimSuccessInfo.ratios.trait_ratio * 10000 + claimSuccessInfo.ratios.num_heros_ratio * 10000 - 1 * 10000) / 10000}}x</p>
        </div>

        <div class="claim-success-row" v-if="claimSuccessInfo && claimSuccessInfo.ratios && claimSuccessInfo.ratios.equipment_ratio">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Item Bonus ratio: {{ claimSuccessInfo.ratios.equipment_ratio }}x</p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Hero Hunger&Happiness Ratio: {{ claimSuccessInfo.ratios.hunger_happiness_ratio }}x</p>
        </div>

        <!-- <div class="claim-success-row" v-if="claimSuccessData.money.base && claimSuccessData.exp.base">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Base Bonus: {{ claimSuccessData.money.base }} Ordz and {{ claimSuccessData.exp.base }} Power</p>
        </div> -->

        <!-- <div class="claim-success-row" v-if="claimSuccessData.money.holder_bonus && claimSuccessData.exp.holder_bonus">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Holder Bonus: {{ claimSuccessData.money.holder_bonus | floorNumber }} Ordz&nbsp;&nbsp;{{ claimSuccessData.exp.holder_bonus | floorNumber }} Power</p>
        </div> -->

        <!-- <div class="claim-success-row" v-if="claimSuccessData.money.hero_bonus && claimSuccessData.exp.hero_bonus">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Hero Bonus: {{claimSuccessData.money.hero_bonus | floorNumber }} Ordz&nbsp;&nbsp;{{ claimSuccessData.exp.hero_bonus | floorNumber }} Power</p>
        </div> -->

        <!-- <div class="claim-success-row" v-if="claimSuccessData.money.tool && claimSuccessData.exp.tool">
          <img class="claim-success-icon" src="@/assets/img/success.png" alt="">
          <p class="score">Item Bonus: {{ claimSuccessData.money.tool | floorNumber }} Ordz&nbsp;&nbsp;{{ claimSuccessData.exp.tool | floorNumber }} Power</p>
        </div> -->

        <!-- 这次获得的积分 -->
        <!-- <div class="claim-success-row" v-if="claimSuccessData.score">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score"><span>Total Points Claimed Today：{{ claimSuccessData.score }}</span></p>
        </div> -->
        <!-- 这次获得的积分 -->


        <div class="modal-twitter-share-btn show" style="margin-top: 34px;margin-bottom: 0;">
          <a v-if="currentGame === 'ordz-pfp-2048' || currentGame === 'ordz-pfp-snake' || currentGame === 'ordz-match' || currentGame === 'ordz-match-3322'" class="common-hover-status-bg" href="/ordz-heroes" style="cursor: pointer;">
            <span>Heroes</span>
          </a>
          <a class="common-hover-status-bg" @click="getShareText()" @touchstart="getShareText" style="cursor: pointer;">
            <span>Share</span>
          </a>
        </div>
        </div>
      </template>

      <!-- type history -->
      <template v-else-if="claimModalType === 'history'">
        <div style="width: 100%">

        <div class="claim-success-row">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score"><span>Wallet Total Earn Today</span></p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score">Token Claimed: <span>{{ claimHistoryData.claim_count }}</span></p>
        </div>

        <div class="claim-success-row">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score">Ordz: <span>{{ claimHistoryData.money_plus }}</span></p>
        </div>


        <div class="claim-success-row">
          <img class="claim-success-icon" style="opacity:0;" src="@/assets/img/success.png" alt="">
          <p class="score">Power: <span>{{ claimHistoryData.exp_plus }}</span></p>
        </div>

        </div>
      </template>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as constans from '@/config/constans'
// Utils
import { utilStopBodyScroll, sliceArray } from '@/utils'
// Component
import ComponentLoading from '@/components/loading'
import ComponentVerification from '@/components/verification'


export default {
  name: 'ClaimPointsModal',
  components: {
    ComponentLoading,
    ComponentVerification // component verification
  },
  data () {
    return {
      constans,
      claimInfo: {
        gameName: '',
        email: '',
        token: '',
        address: ''
      },
      claimHeroesGameName: {},

      // collectionName: 'ordz-airdrop',
      // claimName: 'starship_claim'
      claimName: 'pfp_claim',
      collectionName: 'ordz-pfp', //项目名
      currentGame: null,

      /**
       * Claim Points Modal 相关
       */
      claimModalVisible: false, // 控制 claim 弹窗 显示隐藏
      claimModalType: null, // 类型 token confirm success
      claimLevel: null, // claim 关卡名字
      claimScore: 0, // claim 分数
      claimForm: { // claim 表单
        email: '',
        code: '',
        token: '',
        address: ''
      },
      claimSuccessData: {
        money: {},
        exp: {}
      }, // claim 确认后的信息展示
      isClaimLoading: false, // claim loading
      isCheckLoading: false,
      isFirstClaim: true, // 是否是第一次 claim

      /**
       * Modal Common
       */
      // Google Verification 相关
      isShowVerification: false, // 控制人机验证 显示 隐藏
      isVerificationPass: false, // 人机验证 是否通过
      verificationToken: null, // 人机验证 成功 token
      // 邮箱验证码
      emailCodeTimer: null, // 邮箱验证码 定时器
      emailCodeNum: 60, // 倒计时时间（秒）
      isCodeLoading: false, // code loading
      isShowCode: false, // show code


      // heroes
      heroePageIndex: 0, //当前索引
      selectInscriptionId: '',
      selectToken: '',
      isFollower: false, // 是否是 follower
      renderInfoOfConfirm: {
        followed: {},
        holder: {}
      },
      renderInfoOfSuccess: {},
      followTarget: null,
      isTop10Follower: false, // 是否是前 10 follower

      // 下拉菜单
      gameSelectorVisible: false,
      selectHeroesOptions: [{value: 'ordz-pfp-2048', label: 'Ordz Merge'}, {value: 'ordz-pfp-snake', label: 'Ordz Snake'}],

      /**
       * hero 搜索
       */
      searchKeywords: null,
      isSearching: false,
      searchResult: null, // 搜索结果

      currentSelectRoleInfo: {}, // 当前角色信息

      // cliam 成功后要展示的信息
      claimSuccessInfo: {
        ratios: {
          money: {},
          exp: {}
        }
      },
      claimHistoryData: {}

    }
  },
  props: ['isMobile', 'heroeList'],
  watch:{
    // claimFormAddress (value) {
    //   if (!!value && this.showSelectHeroes) {
    //     // 有可选列表，默认选择第一个
    //     if(this.heroeList.length){
    //       this.setSelectInscriptionId(this.heroeList[0].inscription)
    //     }
    //   }
    // },

  },
  filters: {
    floorNumber (number) {
      if (!number) return ''
      return (Math.round(number * 100) / 100)
    }
  },
  computed: {
    // claim 按钮状态
    claimButtonStatus () {
      if (this.isShowCode) {
        return !!(this.claimForm.email && this.claimForm.token && this.selectInscriptionId && this.claimForm.code)
      } else {
        return !!(this.claimForm.email && this.claimForm.token && this.selectInscriptionId)
      }
    },
    claimFormAddress(){
      return this.claimForm.address
    },
    // 可选的角色列表
    optionalList(){
      // 没有买的，直接展示默认的
      // if(!this.heroeList.length){
      //   return constans.defaultHeroesList
      // }
      // 整理格式

      // 只展示选中的图片
      let arr = null
      let heroArr = this.heroeList
      if (this.claimModalType !== 'token' && this.selectInscriptionId) {
        heroArr.forEach(item => {
          if (item.inscription == this.selectInscriptionId) {
            arr = [item]
          }
        })
      }
      if (!arr) {
        arr = heroArr
      }

      let list = arr.map(item => {
        let Clothes = item.Clothes || item.clothes || ''
        if(!!item.hash){
          Clothes = Clothes || 'None'
        }
        let roleImg = {
          Background: item.Background || item.background || '',
          Body: item.Body || item.body || '',
          Face: item.Face || item.face || '',
          Clothes: Clothes,
          Head: item.Head || item.head || '',
          Extra: item.Extra || item.extra || '',
        }
        Object.keys(roleImg).forEach(key => {
          if(!roleImg[key]){
            delete roleImg[key]
          }
        })
        return {
          ...item,
          roleImg
        }
      })
      return list
    },
    // 分页版列表
    paginationOptionalList(){
      let list = []
      let origin = this.optionalList

      if(origin && origin.length){
        list = sliceArray(origin, 4)
      }

      // if(this.optionalList && this.optionalList.length){
      //   list = sliceArray(this.optionalList, 4)
      // }
      // console.log('cjza', list)

      return list
    },
    paginationActive(){
      let actions = {
        prev: this.heroePageIndex > 0,
        next: this.heroePageIndex < this.paginationOptionalList.length-1
      }
      return actions
    },
    // 是否显示选择角色
    showSelectHeroes: function(){
      let showType = ['ordz-pfp-2048', 'ordz-pfp-snake', 'ordz-heroes', 'ordz-match', 'ordz-match-3322']
      return showType.includes(this.currentGame)
    },
  },
  methods: {

    searchKeydown (e) {
      if (e.keyCode === 13) this.handleSearch()
    },

    // 获取搜索结果
    async handleSearch () {
      try {
        if (!this.searchKeywords) return
        if (this.isSearching) return
        this.isSearching = true

        let res = await axios.get(`${constans.apiUrl}/btc_nft/v1/pfp/ordz-pfp/images/${this.searchKeywords}/info`)


        if (res.data.code === 1000) {

          let roleInfo = res.data.data

          if (roleInfo && roleInfo.hash) {

            // 如果搜索的 hero，是用户拥有的，那么不赋值
            let flag = true
            this.heroeList.forEach((item) => {
              if (roleInfo.ins_number === item.inscription) {
                flag = false
              }
            })

            // 正常赋值
            if (flag) {
              // 格式化数据
              // let list = arr.map(item => {
              //   let Clothes = item.Clothes || item.clothes || ''
              //   if(!!item.hash){
              //     Clothes = Clothes || 'None'
              //   }
              //   let roleImg = {
              //     Background: item.Background || item.background || '',
              //     Body: item.Body || item.body || '',
              //     Face: item.Face || item.face || '',
              //     Clothes: Clothes,
              //     Head: item.Head || item.head || '',
              //     Extra: item.Extra || item.extra || '',
              //   }
              //   Object.keys(roleImg).forEach(key => {
              //     if(!roleImg[key]){
              //       delete roleImg[key]
              //     }
              //   })
              //   return {
              //     ...item,
              //     roleImg
              //   }
              // })

              // 赋值
              // this.searchResult = list[0]
              this.searchResult = roleInfo
              if (this.searchResult.ins_number && !this.searchResult.inscription) {
                this.searchResult.inscription = this.searchResult.ins_number
              }
              // 特殊标识
              this.searchResult.origin = 'help'
              // 选中
              this.setSelectInscriptionId(this.searchResult.inscription)
              this.heroeList = [this.searchResult, ...this.heroeList]
            }

          }
         console.log('this.searchResult', this.searchResult)
        }

        this.isSearching = false
      } catch (error) {
        this.isSearching = false
        console.log('handleSearch error: ', error)
      }
    },

    // claim 弹窗 显示弹窗
    async claimOpenModal (data) {
      this.claimInfo = data
      // 游戏名
      this.currentGame = data.gameName
      // 跟随的人的信息
      this.followTarget = data.followTarget
      // 有邮箱就赋值
      if (data && data.email) {
        this.claimForm.email = data.email
      }
      // 有地址就赋值
      if (data && data.address) {
        this.claimForm.address = data.address

        // 小游戏
        // cjza
        // this.currentGame = 'ordz-pfp-2048'
        if(this.currentGame === 'ordz-pfp-2048' || this.currentGame === 'ordz-pfp-snake' || this.currentGame === 'ordz-match' || this.currentGame === 'ordz-match-3322'){
          // 设置默认选中
          // this.claimHeroesGameName = this.currentGame === 'ordz-pfp-2048' ? this.selectHeroesOptions[0] : this.selectHeroesOptions[1]

          //选中上次 claim 成功的角色
          let ins = localStorage.getItem(`bitcoin_ords.games_${this.claimForm.address}_cliam`)
          console.log('xxx', ins)
          if (ins) {
            this.heroeList.forEach((item, index) => {
              console.log('xxx', item.inscription, ins)

              if (item.inscription == ins) {
                this.selectInscriptionId = item.inscription
              }
            })
          } else {
            // if(this.heroeList.length){
            //   this.setSelectInscriptionId(this.heroeList[0].inscription)
            // }
          }

        // 养成游戏
        } else if(this.currentGame === 'ordz-heroes') {
          // 设置默认选中
          // this.claimHeroesGameName = this.selectHeroesOptions[0]
          // 应默认选中当前角色
          let roleInfo = data.currentRole
          if (roleInfo && roleInfo.inscription) {
            this.selectInscriptionId = roleInfo.inscription
          } else {
            // if(this.heroeList.length){
            //   this.setSelectInscriptionId(this.heroeList[0].inscription)
            // }
          }
        }

      }
      // 有 token 就赋值
      if (data && data.token) {
        this.claimForm.token = data.token
      }

      // // 已通关
      // if (data.token) {
      //   // 已登录
      //   if (data.address) {
      //     // 自动执行第一步 check，成功就到第二步
      //     this.checkClaimToken(() => {
      //       this.isShowVerification = true
      //       this.toggleClaimModal(true)
      //     })

      //   // 未登录
      //   } else {
      //     // 自动执行第一步 check，成功就到第二步
      //     this.checkClaimToken(() => {
      //       this.toggleClaimModal(true)
      //     })
      //   }

      // // 未通关
      // } else {
      //   this.claimModalType = 'token'
      //   this.toggleClaimModal(true)
      // }

      this.claimModalType = 'token'
      this.toggleClaimModal(true)

      // 有可选列表，默认选择第一个
      if(this.heroeList.length && !this.selectInscriptionId){
        this.setSelectInscriptionId(this.heroeList[0].inscription)
      }

    },

    checkBc1Address (address) {
      address = address.toLowerCase()
      return address.indexOf('bc1') === 0
    },

    async handleHistory () {
      try {
        if (this.isCheckLoading) return
        this.isCheckLoading = true

        let res = await axios.get(`${constans.apiUrl}/btc_nft/v1/pfp_claim/${this.claimForm.address}/today_claim_count`)

        if (res.data.code === 1000) {

          this.claimHistoryData = res.data.data

          this.heroePageIndex = 0
          this.claimModalType = 'history'
        } else {
          this.$showMessage('error', res.data.message || 'error')
        }
        this.isCheckLoading = false
      } catch (error) {
        this.isCheckLoading = false
        console.log(error)
      }
    },

    // claim 弹窗 第一阶段按钮
    handleClaim () {
      // 如果有 code 需要先进行 code 验证
      if (this.isShowCode) {
        this.checkEmailCode('claim', () => {
          this.claimModalType = 'confirm'
        })
      } else {
        // 验证 邮箱 与 token
        this.checkClaimToken()
      }
    },

    // claim 弹窗 验证 token 是否有效
    async checkClaimToken (callback) {
      try {
        if (this.isClaimLoading) return
        this.isClaimLoading = true

        // 创建/同步角色
        // let updateRes = await this.updateHeros()
        // if(updateRes.code !== 1000){
        //   this.isClaimLoading = false
        //   this.$showMessage('error', updateRes.message || 'error')
        //   return
        // }

        // 找到 已选择角色的 token
        let hash = null
        let heroArr = this.heroeList
        heroArr.forEach((item) => {
          // 找到已选择的角色信息
          if (item.inscription == this.selectInscriptionId) {
            // 保存当前选择的信息
            this.currentSelectRoleInfo = item
            // 是白板角色
            if (item.token && !item.hash) {
              this.selectToken = item.token
              this.isFollower = true
            // 正常 pfp 角色
            } else {
              hash = item.hash
            }
          }
        })
        // 有 hash，说明不是白板，需要去找 对应的 token
        if (hash) {
          let tokenRes = await axios.post(`${constans.herosApiLink}/ordz_hero/v1/followers`, {
            hashes: [hash]
          })
          if (tokenRes.data.code === 1000) {
            if (tokenRes.data.data.heros && tokenRes.data.data.heros.length) {
             this.selectToken = tokenRes.data.data.heros[0].token
            } else {
              this.$showMessage('error', 'This hero has not yet been created')
              this.isClaimLoading = false
              return
            }
          }
        }

        let params = null
        // 判断选择的角色是不是代打
        if (this.searchResult && this.currentSelectRoleInfo && this.currentSelectRoleInfo.origin === 'help') {
          params = {
            address: null,
            email: this.claimForm.email,
            token: this.claimForm.token,
            hero: null,
            help_address: this.currentSelectRoleInfo.owner_address,
            help_hero: this.selectToken
          }
        // 正常执行
        } else {
          // 如果是白板用户，需要传 跟随目标人的信息
          if (this.isFollower && this.followTarget) {
            params = {
              address: this.claimForm.address,
              email: this.claimForm.email,
              token: this.claimForm.token,
              hero: this.selectToken,
              follow_address: this.followTarget.owner_address,
              follow_hero: this.followTarget.owner_hero_token
            }
          } else {
            params = {
              address: this.claimForm.address,
              email: this.claimForm.email,
              token: this.claimForm.token,
              hero: this.selectToken
            }
          }
        }


        let res = await axios.post(`${constans.apiUrl}/btc_nft/v1/${this.claimName}/${this.collectionName}/check`, params)
        // cjza
        // res.data.code = 1000
        // res.data.data = {
        //   score: 990,
        //   level: '1A'
        // }

        if (res.data.code === 1000) {

          const {score, level, address, bonus_data} = res.data.data

          // if (this.isFollower) {
          //   this.renderInfoOfConfirm = bonus_data.followed
          // } else {
          //   this.renderInfoOfConfirm = bonus_data.holder
          // }
          this.renderInfoOfConfirm = bonus_data

          // 赋值
          this.claimScore = score
          // this.claimLevel = level

          if (address) {
            this.isFirstClaim = false
            this.claimForm.address = address
          }

          // 如果是 follower
          if (this.isFollower) {
            // 判断是否是前 10 follow
            let fRes = await axios.get(`${constans.herosApiLink}/ordz_hero/v1/heros/${this.followTarget.owner_hero_token}/followers`, {
              params: {
                page: 1,
                page_size: 10
              }
            })
            if (fRes.data.code === 1000) {
              let followers = fRes.data.data.followers
              if (followers && followers.length) {
                let arr = followers.slice(0, 10)
                arr.forEach(item => {
                  // 在前 10 中
                  if (item.follower_hero_token === this.selectToken) {
                    this.isTop10Follower = true
                  }
                })
              }
            }
            // 获取跟随目标的 name
            let nRes = await axios.post(`${constans.apiUrl}/btc_nft/v1/pfp/ordz-pfp/images`, {
              page: 1,
              page_size: 4,
              hash: [this.followTarget.owner_hero_hash]
            })
            if (nRes.data.code === 1000) {
              let collections = nRes.data.data.collections
              if (collections && collections.length) {
                this.followTarget.owner_name = collections[0].name
              }
            }

          }

          this.heroePageIndex = 0
          this.claimModalType = 'confirm'
          callback && callback()
        } else if (res.data.code === 20218) {
          this.$showMessage('error', 'Invalid Token or Email, please check again')
        } else if (res.data.code === 20219) {
          this.$showMessage('error', 'Token Has already Claimed')
        // 输入邮箱和任意已领过的 token，或者同一关卡不能再领的 token 跳新的弹窗，展示今天领过的总分
        } else if (res.data.code === 20230) {
          // this.claimSuccessData = res.data.data
          // this.claimModalType = 'success'
          // this.$showMessage('error', res.data.message || 'error')
          this.$showMessage('error', 'Token Has already Claimed')
        } else if (res.data.code === 20553) {
          this.$showMessage('error', 'Each Hero Can only claim 3 times a day')
        } else {
          this.$showMessage('error', res.data.message || 'error')
        }
        this.isClaimLoading = false
      } catch (error) {
        this.isClaimLoading = false
        console.log(error)
      }
    },

    // claim 弹窗 显示人机验证
    claimShowVerification () {

      // this.confirmClaim()

      if (!this.claimForm.address || !this.checkBc1Address(this.claimForm.address)) {
        this.$showMessage('error', 'Please enter your BTC receiving address to claim points')
        return
      }
      this.isShowVerification = true
    },

    // claim 弹窗 人机验证回调
    claimVerificationCallback (token) {
      this.confirmClaim(token)
    },

    // claim 弹窗 确认 claim
    async confirmClaim (token) {
      try {
        if (this.isClaimLoading) return
        this.isClaimLoading = true

        let params = null
        // 判断选择的角色是不是代打
        if (this.searchResult && this.currentSelectRoleInfo && this.currentSelectRoleInfo.origin === 'help') {
          params = {
            address: null,
            email: this.claimForm.email,
            token: this.claimForm.token,
            hero: null,
            help_address: this.currentSelectRoleInfo.owner_address,
            help_hero: this.selectToken
          }
        // 正常执行
        } else {
          // 如果是白板用户，需要传 跟随目标人的信息
          if (this.isFollower && this.followTarget) {
            params = {
              address: this.claimForm.address,
              email: this.claimForm.email,
              token: this.claimForm.token,
              hero: this.selectToken,
              follow_address: this.followTarget.owner_address,
              follow_hero: this.followTarget.owner_hero_token
            }
          } else {
            params = {
              address: this.claimForm.address,
              email: this.claimForm.email,
              token: this.claimForm.token,
              hero: this.selectToken
            }
          }
        }

        let res = await axios.post(`${constans.apiUrl}/btc_nft/v1/${this.claimName}/${this.collectionName}/submit`, params,
          {
            headers: {'captcha-token': token}
          }
        )

        // cjza
        // res.data.code = 1000
        // res.data.data = {
        //   level_detail: '1A',
        //   level_hash: '62bebe0ab2c50a94ccc7d5cbafcf6467173e6e8dfacd7d0ffe14667903b09de2i0',
        //   score: 1600, // 总分
        //   claim_score: 500, // 基础分
        //   first_claim_score: 100, // 首次通关分
        //   all_stage_win_score: 1000  // 通关分
        // }

        if (res.data.code === 1000) {

          this.claimSuccessInfo = res.data.data.bonus_data.holder

          let address = null
          let token = this.selectToken
          // 判断选择的角色是不是代打
          if (this.searchResult && this.currentSelectRoleInfo && this.currentSelectRoleInfo.origin === 'help') {

            address = this.currentSelectRoleInfo.owner_address

          } else {

            address = this.claimForm.address

            // 存储到本地
            // cjza
            // this.currentGame = 'ordz-pfp-2048'
            if(this.currentGame === 'ordz-pfp-2048' || this.currentGame === 'ordz-pfp-snake' || this.currentGame === 'ordz-match' || this.currentGame === 'ordz-match-3322') {
              // 记录 claim 成功 的 地址 和 inscription number 历史
              localStorage.setItem(`bitcoin_ords.games_${this.claimForm.address}_cliam`, this.selectInscriptionId)
            }

          }

          // 获取积分详情
          let detailRes = await axios.get(`${constans.apiUrl}/btc_nft/v1/pfp_claim/${address}/${token}/today_claim`)
          if (detailRes.data.code === 1000) {
            this.claimSuccessData = detailRes.data.data
          }

          // this.claimSuccessData = res.data.data
          this.claimModalType = 'success'
          this.$showMessage('success','Claim Success')
          // this.claimCloseModal()
        } else if (res.data.code === 20218) {
          this.$showMessage('error', 'Invalid Token or Email, please check again')
        } else if (res.data.code === 20219) {
          this.$showMessage('error', 'Token alredy claimed')
        } else if (res.data.code === 20220) {
          this.$showMessage('error', 'Same stage can only claim once a day')
        } else if (res.data.code === 200109) {
          this.$showMessage('error', 'Verification failed')
        } else {
          this.$showMessage('error', res.data.message || 'error')
        }
        this.isClaimLoading = false
      } catch (error) {
        this.isClaimLoading = false
        console.log(error)
      }
    },

    // claim 弹窗 关闭弹窗
    claimCloseModal () {
      this.toggleClaimModal(false)
      this.isShowVerification = false
      this.isVerificationPass = false
      // 重置表单
      clearInterval(this.emailCodeTimer)
      this.currentGame = null
      this.emailCodeNum = 60
      this.claimLevel = null
      this.claimScore = 0
      this.claimForm = {
        email: '',
        code: '',
        token: '',
        address: ''
      }
      this.isFirstClaim = true
      this.claimSuccessData = {
        money: {},
        exp: {}
      }
      this.claimHistoryData = {}
      this.claimModalType = null
      this.isShowCode = false
      this.isClaimLoading = false
      this.isCheckLoading = false

      this.selectInscriptionId = ''
      this.selectToken = ''
      this.isFollower = false
      this.isTop10Follower = false
      this.renderInfoOfConfirm = {
        followed: {},
        holder: {}
      }

      this.searchKeywords = null
      this.isSearching = false
      this.searchResult = null
      this.currentSelectRoleInfo = null
      this.claimSuccessInfo = {
        ratios: {
          money: {},
          exp: {}
        }
      }

      //
      if((this.currentGame === 'ordz-pfp-2048' || this.currentGame === 'ordz-pfp-snake' || this.currentGame === 'ordz-match' || this.currentGame === 'ordz-match-3322') && this.claimModalType === 'success') {
        let timer = setTimeout(() => {
          clearTimeout(timer)
          window.location.reload()
        }, 400)
      }
    },

    // claim 弹窗 控制显示或隐藏
    toggleClaimModal (flag) {
      try {
        utilStopBodyScroll(flag)
        this.claimModalVisible = flag
      } catch (error) {}
    },

    // common 弹窗 获取邮箱验证码
    async getEmailCode (type) {
      try {
        this.isCodeLoading = true
        let res = await axios.post(
          `${constans.apiUrl}/btc_nft/v1/claim/email_codes/send`,
          {email: this[`${type}Form`].email},
          {headers: {'captcha-token': this.verificationToken}}
        )

        // cjza
        // res.data.code = 1000

        if (res.data.code === 1000) {
          this.emailCodeNum = 60
          this.emailCodeCountdown()
        } else {
          this.$showMessage('error', res.data.message || 'error')
        }
        this.isCodeLoading = false
      } catch (error) {
        this.isCodeLoading = false
        console.log(error)
      }
    },

    // common 弹窗 获取邮箱验证码倒计时 60 秒
    emailCodeCountdown () {
      -- this.emailCodeNum
      clearInterval(this.emailCodeTimer)
      this.emailCodeTimer = setInterval(() => {
        if (this.emailCodeNum === 0) {
          clearInterval(this.emailCodeTimer)
          this.emailCodeNum = 60
        } else {
          -- this.emailCodeNum
        }
      }, 1 * 1000)
    },

    // common 弹窗 验证邮箱验证码
    async checkEmailCode (type, cb) {
      try {
        let data = {
          email: this[`${type}Form`].email,
          email_code: this[`${type}Form`].code
        }
        let res = await axios.post(`${constans.apiUrl}/btc_nft/v1/claim/email_codes/check`, data)
        if (res.data.code === 1000) {
          // 存储到本地
          // localStorage.setItem(`bitcoin_ords.games_${type}_email`, this[`${type}Form`].email)
          cb && cb()
        } else {
          this.$showMessage('error', res.data.message || 'error')
        }
      } catch (error) {
        console.log(error)
      }
    },

    // Share Text
    getShareText () {
      try {
        this.$emit('sharePageUrl')
        // return `https://twitter.com/intent/tweet?hashtags=BTC,Ordinals,OrdinalsNFT,BitcoinNFTs,OrdzGames,Gamefi&text=Come play ${ this.currentGame } ${ this.claimSuccessData.level_detail } from @OrdzGames $ORDG$%0A%0AHappy Ordz Games, Play and Earn!👇%0Ahttps://play.ordz.games/inscription/${this.claimSuccessData.level_hash}`
      } catch (error) {
        console.log(error)
      }
    },


    /**
     * Heroes
     */

    // 修改分页
    setHeroePageIndex(number, status){
      if(!status){
        return
      }
      this.heroePageIndex = number
    },
    // 对象转数组
    getObjectKeys(obj){
      if(!obj){
        return []
      }
      return Object.keys(obj)
    },
    // 选择角色id
    setSelectInscriptionId(id){
      if(this.claimModalType !== 'token'){
        return
      }
      // console.log('cjza id', id)
      this.selectInscriptionId = id
    },

    // 切换 选择器
    toggleGameSelector (flag) {
      this.gameSelectorVisible = flag
    },
    // 选择器 选择
    selectGame (data) {
      this.claimHeroesGameName = data
      this.toggleGameSelector(false)
    },
    // 添加/更改英雄，address获取百板数据， hash获取链上角色
    // async updateHeros(){
    //   let selectHeroeItem = this.heroeList.find(item => item.inscription == this.selectInscriptionId)
    //   let address = !selectHeroeItem.hash ? selectHeroeItem.address : selectHeroeItem.hash
    //   let data = {
    //     address: this.claimInfo.address,
    //   }
    //   if(selectHeroeItem.body_type){
    //     data.body_type = selectHeroeItem.body_type
    //   }else{
    //     data.hash = selectHeroeItem.hash
    //     data.inscription = selectHeroeItem.inscription
    //     data.name = selectHeroeItem.name
    //   }
    //   console.log('updateHeros data=>>', data)
    //   let returnRes = {}
    //   try {
    //     let res = await axios.post(`${constans.herosApiLink}/ordz_hero/v1/heros/${address}`, data)
    //     returnRes = res.data
    //   } catch (error) {
    //     console.error('error=>>', error)
    //   }
    //   return returnRes
    // }

  },
  beforeDestroy () {
    clearInterval(this.emailCodeTimer)
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>
