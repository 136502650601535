<template>
  <div class="lds-ring" :style="`width:${size}px;height:${size}px;`">
    <div v-for="index in 4" :key="index" :style="`border-width:${borderWidth}px;border-color:${color} transparent transparent transparent;`"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20
    },
    borderWidth: {
      type: Number,
      default: 1
    },
    color: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--theme-color);
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--theme-color) transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
