<template>
  <div class="component-connect-wallet-wrapper" @click.stop>

    <!-- Button -->
    <div class="btn-container" :class="[isMobile? 'hide': '']">

      <!-- 未连接 -->
      <template v-if="connectWalletState === 'not'">
        <div class="btn not" @click="toggleModal(true)">
          <i class="iconfont icon-qianbao wallet-icon"></i>
          <span class="btn-text">CONNECT WALLET</span>
        </div>
      </template>

      <!-- 连接中 -->
      <template v-if="connectWalletState === 'connecting'">
        <div class="btn connecting">
          <i class="iconfont icon-qianbao wallet-icon"></i>
          <span class="btn-text">CONNECTING...</span>
        </div>
      </template>

      <!-- 已连接 -->
      <template v-if="connectWalletState === 'connected'">
        <!-- <div class="btn connected" :class="{'active': selectVisible}" @click.stop="toggleSelect(!selectVisible)"> -->
        <div class="btn connected" @click.stop="toggleSelect(!selectVisible)">
          <img class="role-icon" src="@/assets/img/og.gif" alt="">
          <span class="btn-text">{{ mixinFormatAddress(connectWalletInfo.address.toUpperCase(), 4, 4) }}</span>
          <i class="iconfont icon-xiala arrow-icon" :class="{'active': selectVisible}"></i>

          <!-- 下拉框 -->
          <ul class="selector-options common-scrollbar-hidden" :class="{'show': selectVisible}" @click.stop>

            <!-- 个人信息 -->
            <li class="selector-options-item self">
              <div class="selector-options-wallet-icon">
                <img v-if="currentWalletType === 'xverse'" src="@/assets/img/xverse-icon.png" alt="">
                <img v-else-if="currentWalletType === 'unisat'" src="@/assets/img/unisat-icon.png" alt="">
                <img v-else-if="currentWalletType === 'okx'" src="@/assets/img/okx-icon.png" alt="">
                <img v-else-if="currentWalletType === 'bitget'" src="@/assets/img/bitget-icon.png" alt="">
                <img v-else-if="currentWalletType === 'hiro'" src="@/assets/img/hiro_icon1.png" alt="">
              </div>
              <div class="selector-options-wallet-info">
                <p class="address" @click="mixinCopySomething(connectWalletInfo.address)">wallet <i class="iconfont icon-fuzhi"></i></p>
                <!-- <p class="balance">{{ connectWalletInfo.balance }}</p> -->
                <p class="balance">{{ mixinFormatAddress(connectWalletInfo.address.toUpperCase(), 4, 4) }}</p>
              </div>
              <div class="qrcode-entry common-hover-status-color-opacity" @click="showQrcode()">
                <i class="material-icons qrcode-icon">qr_code</i>
              </div>
            </li>

            <!-- deposit 余额 -->
            <li class="selector-options-item self">
              <div class="selector-options-wallet-icon">
                <img src="@/assets/img/og.gif" alt="">
              </div>
              <div class="selector-options-wallet-info">
                <p class="top">{{ connectWalletInfo.depositNumber }} $ORDG</p>
                <!-- <div class="bottom og-handle-box">
                  <button class="og-handle-btn deposit" @click.stop="showModalOfDepositToken()">deposit</button>
                  <button class="og-handle-btn withdraw" @click.stop="showModalOfWithdrawToken()">withdraw</button>
                </div> -->
                <!-- <p class="bottom common-highlight common-hover-status-color-opacity common-pointer" @click.stop="showModalOfWithdrawToken()">Withdraw</p> -->
              </div>
            </li>

            <li class="selector-options-item" @click.stop="goProfile()">
              <i class="iconfont icon-profile selector-options-item-icon"></i>
              <p class="selector-options-item-text">My Profile</p>
            </li>

            <!-- 切换钱包 -->
            <li class="selector-options-item" @click.stop="changeWallet()">
              <i class="iconfont icon-fuzhibeifen selector-options-item-icon"></i>
              <p class="selector-options-item-text">Switch Wallet</p>
            </li>

            <!-- profile -->
            <!-- <li class="selector-options-item" @click.stop="toggleSelect(false); toAirdrop()">
              <i class="iconfont icon-profile selector-options-item-icon"></i>
              <p class="selector-options-item-text">AIRDROP</p>
            </li> -->

            <!-- 退出连接 -->
            <li class="selector-options-item" @click.stop="signOut()">
              <i class="iconfont icon-dengchu selector-options-item-icon"></i>
              <p class="selector-options-item-text">Sign out</p>
            </li>

          </ul>

          <!-- 二维码 -->
          <div v-if="qrcodeVisible" class="qrcode-box" @click.stop>
            <i class="material-icons common-hover-status-color-opacity qrcode-close-icon" @click.stop="closeQrcode()">close</i>
            <img class="qrcode-camera-icon" src="@/assets/img/camera-icon.png" alt="">
            <p class="qrcode-desc">Scan QR Code to login on Mobile</p>
            <img class="qrcode-preview" :src="qrcodeUrl" alt="">
          </div>

        </div>
      </template>

    </div>

    <!-- Connect Wallet Modal -->
    <div class="modal-mask" v-if="modalVisible" @click.stop>
      <div class="modal-content">
        <!-- close  -->
        <div class="modal-close" @click="toggleModal(false)"></div>

        <!-- title -->
        <h6 class="modal-title">CONNECT WALLET</h6>

        <!-- subtitle -->
        <p class="modal-subtitle">Choose how you want to connect. If you don't have a wallet, you can select a provider and create one.</p>

        <!-- wallet list -->
        <div class="wallet-list">

          <!-- wallet - xverse -->
          <div class="wallet-item" :class="{'active': currentWalletType === 'xverse'}" @click="handleConnect('xverse')">
            <div class="wallet-item-info">
              <img class="wallet-item-icon" src="@/assets/img/xverse-icon.png" alt="">
              <p>Xverse</p>
            </div>
            <div class="wallet-item-select"></div>
          </div>

          <!-- wallet - unisat -->
          <div class="wallet-item" :class="{'active': currentWalletType === 'unisat'}"  @click="handleConnect('unisat')">
            <div class="wallet-item-info">
              <img class="wallet-item-icon" src="@/assets/img/unisat-icon.png" alt="">
              <p>Unisat</p>
            </div>
            <div class="wallet-item-select"></div>
          </div>

          <!-- wallet - okx -->
          <div class="wallet-item" :class="{'active': currentWalletType === 'okx'}"  @click="handleConnect('okx')">
            <div class="wallet-item-info">
              <img class="wallet-item-icon" src="@/assets/img/okx-icon.png" alt="">
              <p>OKX Wallet</p>
            </div>
            <div class="wallet-item-select"></div>
          </div>
          <!-- wallet - bitget -->
          <div class="wallet-item" :class="{'active': currentWalletType === 'bitget'}"  @click="handleConnect('bitget')">
            <div class="wallet-item-info">
              <img class="wallet-item-icon" src="@/assets/img/bitget-icon.png" alt="">
              <p>Bitget Wallet</p>
            </div>
            <div class="wallet-item-select"></div>
          </div>
          <!-- wallet - hiro -->
<!--          <div class="wallet-item" :class="{'active': currentWalletType === 'hiro'}"  @click="handleConnect('hiro')">-->
<!--            <div class="wallet-item-info">-->
<!--              <img class="wallet-item-icon" src="@/assets/img/hiro_icon1.png" alt="">-->
<!--              <p>Leather Wallet</p>-->
<!--            </div>-->
<!--            <div class="wallet-item-select"></div>-->
<!--          </div>-->
        </div>

        <div class="withdraw-entry" v-if="!connectWalletInfo.address">
          <div class="withdraw-entry-btn" @click="showModalOfWithdrawToken()">Check my deposit</div>
          with address & Email
        </div>
      </div>
    </div>

    <!-- login address modal-->
    <div class="modal-mask" v-if="addressModalVisible" @click.stop>
      <div class="modal-content">
        <!-- title -->
        <h6 class="modal-title">CHOOSE ADDRESS</h6>

        <!-- subtitle -->
        <p class="modal-subtitle" style="text-align: center">Choose how you want to connect.</p>

        <!-- wallet list -->
        <div class="wallet-list">
          <div class="wallet-item" v-for="(item, index) in loginAddresses" :key="item.address" @click="chooseLoginAddress(item)">
            <span>ADDRESS {{ index + 1 }} :</span>
            <span>{{ mixinFormatAddress(item.address.toUpperCase(), 4, 4) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 扫码识别 -->
    <scan-qrcode v-if="showScanQrcode"
      @updateConnectWalletInfoMutation="updateConnectWalletInfoMutation"
      @closeScanQrcode="closeScanQrcode"
      @updateGlobalMessage="updateGlobalMessage"
    />

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'

// Utils
import { utilCreateQRCode, cookies} from '@/utils'
import utilConnectWallet from '@/utils/wallet'
import * as constans from "@/config/constans";
import ScanQrcode from "./scan-qrcode";
import dayjs from "dayjs";

export default {
  name: 'connect-wallet',
  data () {
    return {
      currentWalletType: null, // 钱包类型 xverse unisat
      modalVisible: false, // 控制 modal 显示隐藏
      selectVisible: false, // 控制 下拉框 显示隐藏

      connectWalletState: 'not', // 连接状态 not-未连接 connecting-连接中 connected-已连接
      connectWalletInfo: { // 连接的钱包信息
        type: null, // 钱包类型 xverse unisat
        address: '', // 钱包地址
        balance: 0, // 钱包余额
        depositNumber: 0, //钱包存款
      },
      showScanQrcode: false, //是否显示扫码识别

      qrcodeVisible: false, // 控制 二维码 显示隐藏
      qrcodeUrl: null,
      loginAddresses: [],   // 登录连接钱包的多个可选择地址
      addressModalVisible: false,   // 选择地址弹窗 modal 开关
    }
  },
  components:{
    ScanQrcode
  },
  props: ['isMobile'],
  computed: {
    ...mapState(['onGamePingMessage', 'sendGameWallet'])
  },
  watch: {
    connectWalletState (state) {
      console.log('connectWalletState=>>>', state)
      // 通知游戏登录状态发生变化
      let messageData = {
        target : 'updateConnectWalletState',
        data: {
          info: state
        }
      }
      utilConnectWallet.sendToGameMessage(messageData)

      if (state === 'connecting') {
        this.toggleModal(false)
      }
    },
    connectWalletInfo: {
      deep: true,
      handler(newVal, oldVal){
        if(!!newVal.address){
          // 发送钱包信息
          this.$emit('updateConnectWalletInfo', newVal)
        }
      }
    }
  },
  created(){
    //监听子级传过来的message事件
    window.addEventListener("message", this.receiveSonMessage, false); // 获取子页面传递过来的数据
  },
  mounted () {
    // 绑定 unisat 事件
    let eventTimer = setTimeout(() => {
      clearTimeout(eventTimer)
      this.bindEventOfUnisat()
    }, 200)

    document.body.addEventListener('click', () => {
      if (this.selectVisible) {
        this.toggleSelect(false)
      }
      if (this.qrcodeVisible) {
        this.closeQrcode()
      }
    })
    // 获取上一次连接记录，如果有 直接触发连接
    let walletType = cookies.get('last-connected-wallet')
    if (walletType) {
      this.updateConnectWalletStateMutation('connecting')
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.handleConnect(walletType, true)
      }, 2000)
    }
    this.mobileAutoConnect()
  },
  beforeDestroy(){
    window.removeEventListener("message", this.receiveSonMessage, false); // 获取子页面传递过来的数据
  },
  destroyed () {
    // 移除 unisat 事件绑定
    this.deleteEventOfUnisat()
  },
  methods: {
    ...mapMutations(['updateOnGamePingMessageMutation', 'updateSendGameWalletMutation', 'updateConnectWalletPublicKey', 'updateConnectWalletVerifyAddress']),

    // 跳转 profile
    goProfile() {
      this.toggleModal(false)
      if (process.env.VUE_APP_EVN === 'production') {
        location.href = 'https://www.ordz.games/profile'
      } else {
        location.href = 'https://staging.ordz.games/profile'
      }
    },
    // 选择登录的地址
    chooseLoginAddress(addressObj) {
      // 更新连接状态
      this.updateConnectWalletStateMutation('connected')
      if (this.currentWalletType !== 'xverse') {
        // 更新公钥
        this.updateConnectWalletPublicKey(addressObj.publicKey || '')
        // 更新链接签名验证地址
        this.updateConnectWalletVerifyAddress(addressObj.address || '')
      }
      // 相关信息 存储到 store
      this.updateConnectWalletInfoMutation({
        type: this.currentWalletType,
        address: addressObj.address
      })
      let verify_address = null
      let loginAddress = addressObj.address
      console.log(loginAddress, "============>loginAddress")
      if (this.currentWalletType === 'xverse') {
        verify_address = loginAddress.startsWith('3') ? loginAddress : this.loginAddresses.find(item => item.address !== loginAddress).address
      }
      // 登录成功 获取用户信息 email code deposit
      this.getUserInfo(loginAddress, verify_address)
      // 存储记录
      // localStorage.setItem('last-connected-wallet', 'hiro')
      if (this.currentWalletType === 'xverse') {
        cookies.set('xverse-me-connection', JSON.stringify({
          expiry: dayjs().add(30, 'm').unix(),
          value: this.loginAddresses
        }))
      }
      this.addressModalVisible = false
    },

    // 移动端自动登录链接
    mobileAutoConnect() {
      if (!this.isMobile) return
      let address = localStorage.getItem('last-login-address')
      let expired = localStorage.getItem('last-login-address-expired')
      let now = dayjs().unix()
      if (address && expired && parseInt(expired) > now) {
        this.updateConnectWalletInfoMutation({
          type: '',
          address,
          balance: 0
        })
        // 获取余额
        this.getUserInfo(address)
      }
    },
    // 显示 二维码
    showQrcode () {
      // 生成 二维码
      let address = window.btoa(this.connectWalletInfo.address);
      utilCreateQRCode(address, (url) => {
        this.qrcodeUrl = url
      }, 3)
      //
      // this.toggleSelect(false)
      //
      this.toggleQrcode(true)
    },

    // 关闭 二维码
    closeQrcode () {
      this.toggleQrcode(false)
    },

    // 切换 二维码
    toggleQrcode (flag) {
      this.qrcodeVisible = flag
    },

    // 收到子页面消息
    receiveSonMessage(event){
      let { target, data} = event.data
      if(target === 'game-content-wallet'){
        if(this.connectWalletInfo.address){
          // 通知游戏登录了
          this.sendToGameMessage(this.connectWalletInfo)
        }else{
          // 连接钱包
          this.openConnectWallet()
        }
      }else if(target === 'game-ping'){
        // 标记已经收到ping了
        this.updateOnGamePingMessageMutation(true)

        // 还没发送过钱包信息
        if(!this.sendGameWallet){
          // 游戏页面启动，发送登录状态
          let messageData = {
            target : 'updateConnectWalletState',
            data: {
              info: this.connectWalletState
            }
          }
          utilConnectWallet.sendToGameMessage(messageData)

          // 通知游戏登录了
          if(this.connectWalletInfo.address){
            this.sendToGameMessage(this.connectWalletInfo)
          }
        }
      }
    },
    // 打开连接钱包
    openConnectWallet(){
      if(this.isMobile){
        // 移动端先调用 xverse app login  登录失败回调中调用 扫码登录
        this.handleConnect('xverse', true, true)
        // 显示弹窗
        // this.showScanQrcode = true
      }else{
        // 子页面想要连接钱包
        this.toggleModal(true)
      }
    },

    updateConnectWalletStateMutation(data){
      this.connectWalletState = data
    },
    updateConnectWalletInfoMutation(data){
      this.connectWalletInfo = data

      // 关闭扫码弹窗
      this.showScanQrcode = false

      // 通知游戏登录了
      this.sendToGameMessage(data)
    },

    // 链接钱包
    handleConnect (type, isAutoTrigger, startScan) {
      try {
        if (!type) return

        // 每次连接 清理上一次记录
        if (!isAutoTrigger) {
          cookies.remove('last-connected-wallet')
          cookies.remove('xverse-me-connection')
        }
        // 成功或者失败的回调
        let callback = (res) => {
          // console.log('链接钱包', res)
          const { state, message, address, balance } = res
          // 登录成功
          if (state === 'success') {
            this.currentWalletType = type

            if (type === 'xverse') {
              this.loginAddresses = address
              this.addressModalVisible = true
            } else if (type === 'hiro') {
              this.loginAddresses = address.filter(item => item.symbol === 'BTC')
              this.addressModalVisible = true
            } else {
              this.updateConnectWalletInfoMutation({
                type,
                address,
                balance
              })
              // 登录成功 获取用户信息 email code deposit
              this.getUserInfo(address)
            }

            cookies.set('last-connected-wallet', this.currentWalletType)

            // 关闭弹窗
            this.toggleModal(false)
            this.toggleSelect(false)

          // 登录失败
          } else if (!isAutoTrigger) {
            this.$emit('updateGlobalMessage', {
              message: message || 'error',
              type: 'error',
            })
          } else if (startScan){
            // 是否打开扫码(xverse login 失败之后打开扫码登录)
            this.showScanQrcode = true
          }
        }
        // 连接钱包
        utilConnectWallet[`${type}Connect`](this, callback)
      } catch (error) {
        console.log('handleConnect:', error)
      }
    },

    // 登录成功 获取用户信息 email code deposit
    async getUserInfo (address, verify_address = null) {
      console.log(111)
      try {
        let data = {address}
        if (verify_address) {
          data.verify_address = verify_address
        }
        let res = await axios.post(`${constans.apiUrl}/btc_nft/v1/cv_accounts/login`, data)
        if (res.data.code === 1000) {
          const { code, email, deposit_number } = res.data.data
          // 相关信息 存储到 store
          this.updateConnectWalletInfoMutation({
            ...this.connectWalletInfo,
            depositNumber: deposit_number,
            code,
            email,
          })

        }
      } catch (error) {
        console.log('getUserInfo error: ', error)
      }
    },

    // 退出
    signOut () {
      utilConnectWallet.signOut(this)
      this.updateConnectWalletInfoMutation({
        type: null,
        address: '',
        balance: 0
      })
      this.$emit('updateConnectWalletInfo', this.connectWalletInfo)


      // 通知游戏退出登录了
      this.sendToGameMessage({
        type: null,
        address: '',
        balance: 0
      })
    },

    // 切换钱包
    changeWallet () {
      this.toggleSelect(false)
      this.toggleModal(true)
    },

    // 切换 modal
    toggleModal (flag) {
      let walletType = cookies.get('last-connected-wallet')
      this.currentWalletType = walletType
      // utilStopBodyScroll(flag)
      this.modalVisible = flag
    },

    // 切换 select
    toggleSelect (flag) {
      let walletType = cookies.get('last-connected-wallet')
      this.currentWalletType = walletType
      this.selectVisible = flag
    },

    // 去个人信息页
    toProfile(){
      window.open('https://www.ordz.games/profile')
    },
    toAirdrop(){
      let url = `${constans.officialWebsiteUrl}/airdrop1`
      if(!!window.myShareCode){
        url += `?og=${window.myShareCode}`
      }
      window.open(url)
    },
    // 整理address显示
    mixinFormatAddress(address, before=8, after=8) {
      if (!address) return ''
      let len = address.length
      if (len < 14) return address
      return `${address.slice(0, before)}...${address.slice(len - after, len)}`
    },

    /**
     * 复制到剪切板
     *
     * @param {string} text 要复制到剪切板的文本
     */
     async mixinCopySomething (text) {
      this.$emit('updateGlobalMessage', {
        type: 'success',
        message: 'copied',
      })
      await navigator.clipboard.writeText(text)
    },

    /**
     * 检查是否是 bc1 开头
     *
     * @param {string} address 要检查的地址
     */
    mixinCheckBc1Address (address) {
      address = address.toLowerCase()
      return address.indexOf('bc1') === 0
    },


    // 绑定 unisat 事件
    bindEventOfUnisat () {
      try {

        if (typeof window.unisat === 'undefined') return

        // 切换钱包事件
        window.unisat.on('accountsChanged', (res) => {
          if (!res[0] || res[0] === this.connectWalletInfo.address) return
          // 退出登录
          this.signOut()
          // 重新连接
          this.handleConnect('unisat')
        })

        // 切换网络事件
        window.unisat.on('networkChanged', (res) => {})

      } catch (error) {
        console.log('bindEventOfUnisat error: ', error)
      }
    },

    // 移除 unisat 事件绑定
    deleteEventOfUnisat () {
      try {
        if (typeof window.unisat === 'undefined') return
        // 切换钱包事件
        window.unisat.removeListener('accountsChanged', () => {})
        // 切换网络事件
        window.unisat.removeListener('networkChanged', () => {})
      } catch (error) {
        console.log('bindEventOfUnisat error: ', error)
      }
    },
    // 给游戏页面发送消息
    sendToGameMessage(data){
      // 通知游戏登录了
      let messageData = {
        target : 'updateConnectWalletInfo',
        data: {
          info: data
        }
      }
      utilConnectWallet.sendToGameMessage(messageData)
    },
    // 关闭扫码弹窗
    closeScanQrcode(){
      this.showScanQrcode = false;
    },
    // 提示
    updateGlobalMessage(message){
      this.$emit('updateGlobalMessage', message)
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "./connect-wallet.scss";

  .component-connect-wallet-wrapper {
    font-family: var(--font-family);
    font-size: 16px;
    color: #fff;
    .btn {
      position: relative;
      display: flex;
      align-items: center;
      // gap: 8px;
      background: var(--theme-color);
      height: 34px;
      padding: 0 12px;
      border-radius: 6px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      border: 1px solid var(--theme-color);
      &:hover {
        opacity: .8;
      }
      .btn-text {
        font-size: 1.14rem;
        transition: all .2s ease-in-out;
      }

      &.connected {
        background: transparent;
        border-color: transparent;
        width: 200px;

        .btn-text {
          color: var(--theme-color);
        }

        &:hover,
        &.active {
          opacity: 1;
          background: rgba(255, 146, 14, .1);
          border-color: var(--theme-color);
          // .btn-text {
          //   color: #fff;
          // }
          // .arrow-icon {
          //   color: #fff;
          // }
        }

        .selector-options {
          position: absolute;
          width: calc(100% + 2px + 16px + 28px + 16px + 28px);
          top: 40px;
          right: -1px;
          z-index: 2;
          background: #000;
          overflow: auto;
          transition: all .3s ease-in-out;
          height: 0;
          background: #1F1F1F;
          border-radius: 8px;

          &.show {
            height: 344px;
          }

          .selector-options-item {
            position: relative;
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 0 20px;
            height: 56px;
            line-height: 56px;
            cursor: pointer;
            transition: all .2s ease-in-out;

            &::after {
              content: "";
              position: absolute;
              left: 50%;
              bottom: -1px;
              transform: translateX(-50%);
              width: calc(100% - 40px);
              height: 1px;
              background: #262626;
            }


            &.self {
              line-height: 1;
              height: 88px;
              cursor: initial;
              &:hover {
                background: transparent;
              }
              .selector-options-wallet-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background: #272727;
                border-radius: 50%;
                >img {
                  width: 38px;
                  height: 38px;
                  object-fit: contain;
                  object-position: center;
                  border-radius: 50%;
                }
              }
              .selector-options-wallet-info {
                .address {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  gap: 8px;
                  >i {
                    color: #414141;
                    transition: all .2s ease-in-out;
                  }
                  &:hover i {
                    color: var(--theme-color);
                  }
                }
                .balance {
                  font-size: .8rem;
                  font-family: var(--font-family-title);
                  margin-top: 10px;
                }
              }
            }

            &:first-of-type {
              border-radius: 8px 8px 0 0;
            }
            &:last-of-type {
              border-radius: 0 0 8px 8px;
              &::after {
                display: none;
              }
            }
            &:hover {
              background: #303030;
            }

            .selector-options-item-icon {
              width: 20px;
              font-size: 16px;
              margin-left: 16px;
            }

            .selector-options-item-text {
              flex: 1;
            }

            .qrcode-entry {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #353535;
              border-radius: 2px;
              cursor: pointer;

              .qrcode-icon {
                font-size: 24px;
              }

            }
          }
        }
      }

      .qrcode-box {
        position: absolute;
        top: 40px;
        right: -1px;
        z-index: 3;
        width: calc(100% + 2px + 16px + 28px + 16px + 28px + 16px + 28px);
        transition: all .3s ease-in-out;
        background: #1F1F1F;
        border-radius: 8px;
        padding: 32px 30px 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: initial;

        .qrcode-close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 24px;
          font-weight: bold;
          color: #424242;
          cursor: pointer;
        }

        .qrcode-camera-icon {
          width: 32px;
          margin-bottom: 18px;
        }

        .qrcode-desc {
          font-size: 1rem;
          color: #BBBCBB;
          line-height: 1.28rem;
          margin-bottom: 32px;
          text-align: center;
        }

        .qrcode-preview {
          width: 214px;
          border-radius: 6px;
        }

      }

      .wallet-icon {
        font-size: 16px;
        margin-right: 12px;
      }

      .role-icon {
        width: 38px;
        height: 38px;
        margin-right: 12px;
      }

      .arrow-icon {
        font-size: 14px;
        margin-left: 10px;
        transition: all .2s ease-in-out;
        color: var(--theme-color);
        margin-top: 2px;
        &.active {
          transform: rotate(180deg);
        }
     }

    }

    .modal-mask {
      .modal-content {
        width: 480px;

        .modal-subtitle {
          color: #757575;
          font-size: 1rem;
          line-height: 1.4;
          margin-top: 24px;
        }

        .wallet-list {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 24px;
          .wallet-item {
            border: 1px solid #414141;
            border-radius: 8px;
            height: 76px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            transition: all .2s ease-in-out;
            cursor: pointer;

            &:hover,
            &.active {
              border-color: var(--theme-color);
              background: rgba(255, 146, 14, .1);

              .wallet-item-select  {
                border-color: var(--theme-color);
                &::after {
                  background: var(--theme-color);
                }
              }

            }

            .wallet-item-info {
              display: flex;
              align-items: center;
              gap: 16px;
              font-size: 1rem;

              .wallet-item-icon {
                width: 38px;
                height: 38px;
                object-fit: contain;
                object-position: center;
                border-radius: 6px;
              }

              >p {
                font-family: var(--font-family-title);
              }

            }

            .wallet-item-select {
              width: 22px;
              height: 22px;
              border: 1px solid #303030;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              &::after {
                content: "";
                width: 50%;
                height: 50%;
                background: transparent;
                border-radius: 50%;
              }

            }

          }
        }

      }
    }

  }

  // @media screen and (max-width: 991px) {
  //   .component-connect-wallet-wrapper {
  //    height: 0
  //   }
  // }
</style>
