<template>
  <div>
    <div v-if="!showTips" id="grecaptcha"></div>
    <p v-else v-html="errorText"></p>
  </div>
</template>

<script>


export default {
  data () {
    return {
      key: '6LfSZzklAAAAAG473mxEEccnhdGymyS_XTXVP7ad',
      stagingKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      errorText: 'Waiting to Verify, <br>please Check proxy servers and firewalls',
      showTips: false
    }
  },
  mounted() {
    this.loaded()
  },
  methods: {
    // 验证成功的 回调
    submit (token) {
      // console.log('successCallback', token)
      this.$emit('successCallback', token)
    },
    // 验证过期的 回调
    expired () {
      // console.log('expiredCallback')
      this.$emit('expiredCallback')
    },
    // 加载异常的 回调
    error () {
      // console.log('error')
      // this.showTips = true
      this.$emit('errorCallback')
    },
    loaded() {
        try {
          // 没有加载 grecaptcha 出现提示文案
          if (!window.grecaptcha || !window.grecaptcha.render) {
            this.error()
          } else {
            window.grecaptcha.render("grecaptcha", {
              sitekey: process.env.NODE_ENV === 'development' ? this.stagingKey : this.key,
              theme: 'dark',
              callback: this.submit,
              'expired-callback': this.expired
            })
            // check verification 是否加载成功
            setTimeout(() => {
              try {
                let length = document.getElementById('grecaptcha').childElementCount
                // length 为 1 加载失败，为 2 加载成功
                if (length === 1) {
                  this.error()
                }
              } catch (error) {
                this.error()
              }
            }, 2 * 1000)
          }
        } catch (error) {
          this.error()
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    width: 360px;
    height: 78px;
    font-size: 0.85rem;
    color: #FF0000;
    text-align: center;
    border: 1px solid #525252;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.4;
  }
</style>
